<template>
  <div class="w-100">
    <b-row>
      <b-col v-for="(item, index) in cardData" :key="index" cols="12" md="6" lg="3">
        <b-card>
          <template #header>
            <b-skeleton class="custom-padding-margin" width="70%" v-if="localIsBusy" />
            <p v-else class="custom-padding-margin">
              {{ item.title }}
            </p>
          </template>
          <b-card-text>
            <div>
              <b-skeleton class="ml-2" width="50%" height="70%" v-if="localIsBusy" />
              <h3 v-else class="ml-2">
                {{ item.text }}
              </h3>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { formatarValor } from '@/utils/Functions/Formatacao/Formatacao'
  import useJwt from '@/auth/jwt/useJwt'
  export default {
    components: {
      formatarValor,
    },
    props: {
      isBusy: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        cardData: [],
        localIsBusy: this.isBusy,
      }
    },
    watch: {
      isBusy(val) {
        this.localIsBusy = val
      },
    },
    async mounted() {
      await this.buscaTotalizadores()
    },
    methods: {
      async buscaTotalizadores() {
        this.localIsBusy = true
        useJwt
          .get(`financeiro/totalizadoresContratos`)
          .then((response) => {
            const totalizadores = {
              contratosConsolidados: response.data.contratosConsolidados ? response.data.contratosConsolidados : 0,
              valorTotalConsolidado: response.data.valorTotalConsolidado
                ? formatarValor(response.data.valorTotalConsolidado)
                : 'R$ 0,00',
              valorRemessas: response.data.valorRemessas ? formatarValor(response.data.valorRemessas) : 'R$ 0,00',
              valorLucroLiquido: response.data.valorLucroLiquido ? formatarValor(response.data.valorLucroLiquido) : 'R$ 0,00',
              valorJuros: response.data.valorJuros ? formatarValor(response.data.valorJuros) : 'R$ 0,00',
            }

            this.cardData = [
              {
                title: 'Contratos Consolidados',
                text: totalizadores.contratosConsolidados,
              },
              {
                title: 'Valor total Consolidado',
                text: totalizadores.valorTotalConsolidado,
              },
              {
                title: 'Valor histórico de lucro liquido',
                text: totalizadores.valorLucroLiquido,
              },
              {
                title: 'Valor histórico total de juros',
                text: totalizadores.valorJuros,
              },
            ]
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.localIsBusy = false
          })
      },
    },
  }
</script>
<style scoped>
  .custom-padding-margin {
    padding: 0px;
    margin: 0px;
  }
  .card-header {
    padding-bottom: 0.3rem;
  }

  div.card-body {
    padding: 0px;
  }
  .custom-h {
    height: 10vh;
  }
</style>
