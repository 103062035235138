<template>
  <form-wizard
    shape="circle"
    color="#016b3a"
    :title="null"
    :subtitle="null"
    back-button-text="Voltar"
    next-button-text="Próximo"
    finish-button-text="Finalizar"
    class="mb-3"
    @on-complete="salvarProposta"
  >
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <wizard-button v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle" @click.native="props.prevTab()">
          Voltar
        </wizard-button>
      </div>
      <div class="wizard-footer-right w-50 d-flex d-flex justify-content-end w-auto">
        <div class="d-flex flex-row flex-nowrap justify-content-end">
          <div v-if="props.activeTabIndex == 2" md="6">
            <b-button variant="primary" @click="calcularProposta" :disabled="calculando" class="wizard-btn btn-primary">
              Calcular
            </b-button>
          </div>
          <div v-if="!props.isLastStep" class="ml-1">
            <wizard-button
              :disabled="!habilitarBotaoProximoCalcular(props.activeTabIndex)"
              :style="props.fillButtonStyle"
              @click.native="props.nextTab()"
            >
              Próximo
            </wizard-button>
          </div>
          <div v-else class="ml-1">
            <b-button variant="primary" :style="props.fillButtonStyle" @click="salvarProposta">
              {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}
            </b-button>
          </div>
        </div>
      </div>
    </template>

    <!-- Segurado -->
    <tab-content v-if="!origemAplicativo" title="Segurado" :before-change="validationDadosSegurado">
      <validation-observer ref="tomRules" tag="form">
        <!-- Consulta Segurado -->
        <b-form-group v-show="campoSeguradoSelecionado" label="Segurado" label-for="segurado">
          <b-form-group label="Buscar por:">
            <b-form-radio-group v-model="tipoBusca" name="buscaPor">
              <b-form-radio size="sm" value="1" variant="success">Nome</b-form-radio>
              <b-form-radio size="sm" value="2" variant="success">CPF</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <validation-provider #default="{ errors }" name="Segurado" rules="required" v-if="tipoBusca == '1'">
            <b-skeleton v-if="isBusySegurado" type="input" />
            <vue-autosuggest
              v-else
              id="segurado"
              v-model="proposta.segurado"
              :suggestions="suggestions"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Digite aqui o nome do segurado',
              }"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center"
                  :disabled="suggestion.item.diasDeCarenciaBloqueado"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="suggestion.item.tomador.foto ? suggestion.item.tomador.foto.foto : null" />
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.tomador.nomeCompleto }}
                      </b-card-text>

                      <small class="text-muted">
                        CPF: {{ suggestion.item.tomador.cpf }} - Categoria:
                        {{ formatarInativoAposentado(suggestion.item.situacaoPrevidenciaria) }} - Matrícula:
                        {{ suggestion.item.matricula }}
                      </small>

                      <b-badge class="ml-1" pill variant="light" style="color: black; font-weight: bold">
                        {{ suggestion.item.tomador.instituto.sigla }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    v-if="suggestion.item.diasDeCarenciaBloqueado"
                    class="tw-text-sm tw-bg-gray-700/60 tw-text-white text-center tw-rounded-3xl tw-p-2"
                  >
                    Período de carência
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider #default="{ errors }" name="Segurado" rules="required" v-else>
            <b-skeleton v-if="isBusySegurado" type="input" />
            <vue-autosuggest
              v-else
              id="cpf-input"
              v-model="proposta.segurado"
              type="number"
              maxlength="14"
              :suggestions="suggestions"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Digite aqui o CPF do segurado',
                maxlength: 14,
              }"
              @selected="getSuggestionValue"
              @input="fetchResults"
            >
              <template slot-scope="{ suggestion }">
                <div
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center"
                  :disabled="suggestion.item.diasDeCarenciaBloqueado"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar :src="suggestion.item.tomador.foto ? suggestion.item.tomador.foto.foto : null" />
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.tomador.nomeCompleto }}
                      </b-card-text>
                      <small class="text-muted">
                        CPF: {{ suggestion.item.tomador.cpf }} - Categoria:
                        {{ formatarInativoAposentado(suggestion.item.situacaoPrevidenciaria) }} - Matrícula:
                        {{ suggestion.item.matricula }}
                      </small>
                      <!--  -->
                      <b-badge class="ml-1" pill variant="light" style="color: black; font-weight: bold">
                        {{ suggestion.item.tomador.instituto.sigla }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    v-if="suggestion.item.diasDeCarenciaBloqueado"
                    class="tw-text-sm tw-bg-gray-700/60 tw-text-white text-center tw-rounded-3xl tw-p-2"
                  >
                    Período de carência
                  </div>
                </div>
              </template>
            </vue-autosuggest>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Campos do tomador -->
        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="CPF" label-for="cpf">
              <b-form-input id="cpf" v-model="segurado.tomador.cpf" readonly type="text" placeholder="CPF" />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Completo" label-for="nomeCompleto">
              <b-form-input
                id="nomeCompleto"
                v-model="segurado.tomador.nomeCompleto"
                readonly
                type="text"
                placeholder="Nome Completo"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Categoria" label-for="categoria">
              <b-form-input
                id="categoria"
                readonly
                type="text"
                placeholder="Categoria"
                :value="formatarInativoAposentado(segurado.categoria)"
              />
            </b-form-group>
          </b-col>
          <b-col md="9">
            <b-form-group label="Nome Social" label-for="nomeSocial">
              <b-form-input
                id="nomeSocial"
                v-model="segurado.tomador.nomeSocial"
                readonly
                type="text"
                placeholder="Nome Social"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="this.exibeDadosSegurado">
          <b-col md="3">
            <b-form-group label="Data de Nascimento" label-for="dataNascimento">
              <b-form-input
                id="dataNascimento"
                v-model="segurado.tomador.dataNascimento"
                readonly
                type="text"
                placeholder="Data de Nascimento"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Remuneração" label-for="remuneracaoProventos">
              <b-form-input
                id="remuneracaoProventos"
                v-model="segurado.remuneracaoProventos"
                readonly
                type="text"
                placeholder="Remuneração"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Utilizada" label-for="margemUtilizada">
              <b-form-input
                id="margemUtilizada"
                v-model="segurado.margemUtilizada"
                readonly
                type="text"
                placeholder="Margem Utilizada"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Margem Disponível" label-for="margemDisponivel">
              <b-form-input
                id="margemDisponivel"
                v-model="segurado.margemDisponivel"
                readonly
                type="text"
                placeholder="Margem Disponível"
                style="text-transform: uppercase"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tipo de Cálculo  -->
    <tab-content title="Tipo de Cálculo" :before-change="validationTipoCalculo">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2"> Tipo de Cálculo </b-card-title>

      <validation-observer ref="tipRules" tag="form" rules="required">
        <b-row>
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Selecionar Produto">
              <validation-provider #default="{ errors }" name="Selecionar Produto" rules="required">
                <v-select-pt
                  id="selectProduto"
                  v-model="proposta.produtoId"
                  name="selectProduto"
                  :options="listaProdutos"
                  :reduce="(option) => option.value"
                  label="text"
                  placeholder="Selecione:"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Forma de Cálculo. Por valor da parcela ou pelo valor do empréstimo -->
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Forma de Cálculo">
              <validation-provider #default="{ errors }" name="Forma de Cálculo" rules="required">
                <b-form-radio-group
                  id="radiosFormasDeCalculo"
                  v-model="proposta.formaCalculo"
                  :options="optionsFormaCalculo"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :md="origemAplicativo ? 12 : 6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Compra de Dívida?">
              <validation-provider #default="{ errors }" name="Compra de Dívida?" rules="required">
                <b-form-radio-group
                  id="compraDeDivida"
                  v-model="proposta.compraDeDivida"
                  :options="[
                    { text: 'SIM', value: true },
                    { text: 'NÃO', value: false },
                  ]"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  buttons
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Dados da consignação:  -->
    <tab-content title="Dados da consignação" :before-change="validationDadosConsignacao">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2">
        Dados da consignação
      </b-card-title>

      <validation-observer ref="dadosRules" tag="form">
        <b-row>
          <b-col md="4">
            <b-form-group label="Valor da Proposta" label-for="valor">
              <b-skeleton v-if="calculando" type="input" />
              <validation-provider
                v-else
                #default="{ errors }"
                name="Valor da Proposta"
                :rules="{
                  required: true,
                  valor: Number(proposta.valorMaximoGeral).toFixed(2),
                  valor_minimo: formatarParaNumber(parametroValorMinimoEmprestimo),
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>

                  <b-form-input
                    id="valor"
                    v-model="proposta.valor"
                    placeholder="Valor da Proposta"
                    :readonly="proposta.formaCalculo == 'valorDaParcela'"
                    v-number="number"
                  />
                </b-input-group>
                <small
                  v-if="proposta.valorMaximoGeral != null || proposta.valorMaximoGeral != undefined"
                  class="text-primary d-block"
                >
                  Valor Máx ({{ formatarValor(proposta.valorMaximoGeral) }})
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Parcelas" label-for="parcelas">
              <b-skeleton v-if="calculando" type="input" />
              <validation-provider
                v-else
                #default="{ errors }"
                name="Parcelas"
                :rules="{
                  required: true,
                  parcela: proposta.parcelaMaxima,
                  zero: [],
                }"
              >
                <b-form-input
                  id="parcelas"
                  v-model="proposta.parcelas"
                  v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^0-9]/ }"
                  placeholder="Parcelas"
                  maxlength="3"
                  @keypress="isNumber($event)"
                />
                <small v-if="proposta.parcelaMaxima" class="text-primary d-block"
                  >Parcela(s) Máx ({{ proposta.parcelaMaxima }}x)</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              :label="
                proposta.tipoCalculo == 'SAC'
                  ? 'Valor da 1º Parcela'
                  : proposta.tipoCalculo == 'posFixado'
                  ? 'Valor Previsto'
                  : 'Valor da Parcela'
              "
              label-for="valorPrimeiraParcela"
            >
              <b-skeleton v-if="calculando" type="input" />
              <validation-provider
                v-else
                #default="{ errors }"
                name="Valor da Parcela"
                :rules="{
                  required: true,
                  valor: proposta.valorMaximoPrimeiraParcela,
                  valor_minimo: formatarParaNumber(produto.parametroValorMinimoParcela),
                  zero: [],
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>

                  <b-form-input
                    id="valorPrimeiraParcela"
                    v-model="proposta.valorPrimeiraParcela"
                    v-number="number"
                    :placeholder="
                      proposta.tipoCalculo == 'SAC'
                        ? 'Valor da 1º Parcela'
                        : proposta.tipoCalculo == 'posFixado'
                        ? 'Valor Previsto'
                        : 'Valor da Parcela'
                    "
                    :readonly="proposta.formaCalculo == 'valorDoEmprestimo'"
                  />
                </b-input-group>
                <small
                  v-if="proposta.valorMaximoPrimeiraParcela != null || proposta.valorMaximoPrimeiraParcela != undefined"
                  class="text-primary d-block"
                >
                  Valor Máx ({{ formatarValor(proposta.valorMaximoPrimeiraParcela) }})
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="proposta.compraDeDivida" md="4">
            <b-form-group label="Valor da Dívida" label-for="valor">
              <validation-provider
                #default="{ errors }"
                name="Valor da Dívida"
                :rules="{
                  required: true,
                  zero: [],
                  valor: proposta.valor,
                }"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="valorDaDivida"
                    v-model="proposta.valorDaDivida"
                    v-number="number"
                    placeholder="Valor da Dívida"
                  />
                </b-input-group>
                <small v-if="proposta.valor != null || proposta.valorDaDivida != undefined" class="text-primary d-block">
                  Valor da Dívida ({{
                    formatarValor(
                      formatarParseFloat(proposta.valor ? proposta.valor : '0') -
                        formatarParseFloat(proposta.valorDaDivida ? proposta.valorDaDivida : '0'),
                    )
                  }})
                </small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="1º Parcela" label-for="primeiraParcela">
              <b-skeleton v-if="calculando" type="input" />
              <b-form-input
                v-else
                id="primeiraParcela"
                v-model="proposta.primeiraParcela"
                class="form-control"
                type="text"
                placeholder="DD/MM/AAAA"
                :options="options.cleaveDate"
                :raw="false"
                :readonly="true"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="produto.utilizaIOF" md="4">
            <b-form-group label="Valor do IOF" label-for="valorIOF">
              <b-skeleton v-if="calculando" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorIOF"
                  v-model="proposta.valorIOF"
                  v-number="number"
                  placeholder="Valor do IOF"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Alteração da Genial, tirando nosso cálculo e validações -->
          <!-- <b-col v-if="proposta.compraDeDivida" md="8">
            <b-form-group label="Banco" label-for="dadoBancarioBanco">
              <validation-provider #default="{ errors }" name="Banco" rules="required">
                <v-select-pt
                  v-model="proposta.bancoId"
                  name="dadoBancarioBanco"
                  :options="banco"
                  :reduce="(option) => option.id"
                  label="nome"
                  @input="encontrarNomeBanco(proposta.bancoId)"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col v-if="proposta.compraDeDivida" md="4">
            <b-form-group label="Valor Remanscente" label-for="valorRemanescente">
              <b-skeleton v-if="calculando" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorRemanescente"
                  v-model="proposta.valorRemanescente"
                  v-number="number"
                  placeholder="Valor Remanscente"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Valor Emprestado" label-for="valorTotal">
              <b-skeleton v-if="calculando" type="input" />
              <b-input-group v-else>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorTotal"
                  v-model="proposta.valorComIOF"
                  v-number="number"
                  placeholder="Valor Emprestado"
                  :readonly="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>

    <!-- Tabela de Parcelas  -->
    <tab-content title="Tabela de Parcelas" :before-change="validationTabelaParcelas">
      <b-card-title v-if="origemAplicativo" title-tag="h3" class="font-weight-bold mb-2 mt-2">
        Tabela de Parcelas
      </b-card-title>

      <validation-observer ref="bankRules" tag="form">
        <b-form>
          <b-row>
            <b-table
              striped
              responsive
              foot-clone
              class="position-relative"
              :per-page="perPageParcelas"
              :current-page="currentPageParcelas"
              :items="itemsParcelas"
              :fields="fieldsParcelas"
              :sort-by.sync="sortByParcelas"
              :sort-desc.sync="sortDescParcelas"
              :sort-direction="sortDirectionParcelas"
              :busy="isBusy"
            >
              <template #cell(valor)="row">
                {{
                  proposta.tipoCalculo == 'posFixado'
                    ? formatarValor(row.item.valor) + ' + ' + userData.Instituto.tipoIndiceSelecionado
                    : formatarValor(row.item.valor)
                }}
              </template>

              <template #cell(vencimento)="row">
                {{ formatarData(row.item.vencimento) }}
              </template>

              <template #foot(parcela)>
                <span>Total: </span>
              </template>

              <template #foot(valor)>
                <span>{{ valorTotalParcelas ? valorTotalParcelas : 0.0 }}</span>
              </template>

              <template #foot(vencimento)>
                {{ '' }}
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
              <b-pagination
                v-model="currentPageParcelas"
                :total-rows="totalRowsParcelas"
                :per-page="perPageParcelas"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-card-body>
          </b-row>
        </b-form>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import {
    arrendondarCalculo,
    calcularIdade,
    detectarNavegador,
    formatarValorReais,
    isNumber,
    desformatarCNPJ,
    desformatarCPF,
  } from '@/libs/utils'
  import {
    formatarData,
    formatarInativoAposentado,
    formatarParaNumber,
    formatarParseFloat,
    formatarValor,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import { isCPFValid } from '@/utils/Functions/Validacoes/Validacoes'
  import Banco from '@core/components/banco/Banco.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required, valor } from '@validations'
  import { parse } from 'date-fns'
  import * as math from 'mathjs'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import { VueAutosuggest } from 'vue-autosuggest'
  import Cleave from 'vue-cleave-component'
  import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Ripple from 'vue-ripple-directive'
  import { showLoading, closeLoading } from '@/views/components/Loading'

  export default {
    components: {
      Banco,
      ValidationProvider,
      WizardButton,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
      FormWizard,
      TabContent,
      formatarData,
      calcularIdade,
      formatarParaNumber,
      formatarInativoAposentado,
      arrendondarCalculo,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        origem: {
          type: String,
        },
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,

        // Controle de estado
        isBusySegurado: false,
        isBusy: false,
        loading: false,
        calculando: false,
        calculou: false,

        // Funções utilizadas
        formatarParaNumber,
        formatarInativoAposentado,
        parametroValorMinimoEmprestimo: 0,
        parametroValorMinimoParcela: 0,
        formatarParseFloat,
        formatarValor,
        formatarData,
        isNumber,

        banco: [],
        origemAplicativo: false,
        number: {},
        proposta: {
          bancoId: undefined,
          status: 0,
          valorComIOF: 0,
          parcelaComIOF: 0,
          valorIOF: 0,
          taxaIOF: 0,
          compraDeDivida: false,
          valorDaDivida: 0,
          valorTotal: undefined,
          tipoCalculo: null,
          valorRemanescente: 0,
        },
        segurado: { tomador: {} },
        suggestions: [],
        timeout: null,
        debounceMilliseconds: 300,
        required,
        valor: 0,

        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 12,
        currentPageParcelas: 1,
        fieldsParcelas: ['parcela', 'valor', 'vencimento'],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        valorTotalParcelas: 0,

        exibeDadosSegurado: false,
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        optionsFormaCalculo: [
          { value: 'valorDoEmprestimo', text: 'Valor do Empréstimo' },
          { value: 'valorDaParcela', text: 'Valor da Parcela' },
        ],
        dados: [],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          statusOpcoes: '',
        },
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'GerouProposta', text: 'Gerou Proposta' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Expirado', text: 'Expirado' },
        ],
        campoSeguradoSelecionado: true,

        // Permissões
        utilizaIOF: false,
        utilizaFloatFinanceiro: false,

        // Ýndices de Referência
        competenciaDaTaxa: null,
        taxaDaCompetencia: null,

        // Produto
        listaProdutos: [],
        produto: {
          tipoIndiceSelecionado: null,
          mesVencimento: null,
          aliquotaDiariaIOF: null,
          aliquotaEfetivaIOF: null,
          tipoVencimentoParcela: null,
          diaFixoVencimentoParcela: null,
          utilizaIOF: null,
          utilizaFloatFinanceiro: null,
          valorMinimoParcela: null,
          valorMinimoEmprestimo: null,
        },
        tipoBusca: '1',
      }
    },
    async created() {
      this.origemAplicativo = this.parametro.origem == 'Aplicativo'
      if (this.origemAplicativo) this.preparaComponenteParaAplicativo()
    },
    async mounted() {
      this.exibeDadosSegurado = false
      this.proposta.formaCalculo = 'valorDoEmprestimo'
      // Alteração da Genial, tirando nosso cálculo e validações
      // await this.carregarBancos()
      this.carregarListaDeProdutos()
      this.origemAplicativo = this.parametro.origem == 'Aplicativo'
    },
    methods: {
      habilitarBotaoProximoCalcular(aba) {
        if (aba === 2) {
          return this.calculou === true
        }
        return true
      },
      // Alteração da Genial, tirando nosso cálculo e validações
      // findClosestValue(arr, target) {
      //   target = formatarParaNumber(target)
      //   if (!arr || arr.length === 0) {
      //     return null
      //   } // Handle empty array case

      //   let closest = Number(Number(arr[0].parcelaReal).toFixed(2))
      //   let index = 0
      //   for (let i = 0; i < arr.length; i++) {
      //     if (Math.abs(target - arr[i].parcelaReal) < Math.abs(target - closest)) {
      //       closest = Number(Number(arr[i].parcelaReal).toFixed(2))
      //       index = i
      //     }
      //   }

      //   return arr[index]
      // },

      // Alteração da Genial, tirando nosso cálculo e validações
      // async carregarBancos() {
      //   await useJwt
      //     .get('utils/bancos')
      //     .then((response) => {
      //       this.banco = response.data
      //       this.banco.forEach((banco) => {
      //         if (banco.codigo) banco.nome = `${String(banco.codigo)} - ${String(banco.nome)}`
      //       })
      //     })
      //     .catch((error) => {
      //       console.error(error)
      //     })
      // },
      validarCalculoParcelas() {
        if (this.proposta.parcelas == undefined || !(this.proposta.parcelas > 0)) {
          this.$message.error('Preencha o número de parcelas.')
          return false
        }
        if (this.proposta.valorPrimeiraParcela == undefined) {
          this.$message.error('Preencha o valor de parcela.')
          return false
        }
        return true
      },

      // Alteração da Genial, tirando nosso cálculo e validações
      // encontrarNomeBanco(id) {
      //   const bancoEncontrado = this.banco.find((banco) => banco.id === id)
      //   if (bancoEncontrado) {
      //     this.proposta.nomeBanco = bancoEncontrado.nome
      //   } else {
      //     console.error('Não encontrado banco com o id:', this.proposta.bancoId)
      //   }
      // },

      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularvalorRemanescente() {
      //   const valor = formatarParaNumber(this.proposta.valor)
      //   const valorDaDivida = formatarParaNumber(this.proposta.valorDaDivida)
      //   if (valor > valorDaDivida) {
      //     this.proposta.valorRemanescente = formatarValorReais(valor - valorDaDivida)
      //   } else {
      //     this.proposta.valorRemanescente = formatarValorReais('0.00')
      //   }
      // },
      async calcularProposta(event) {
        this.calculou = true
        this.calcularComGenial()

        // Alteração da Genial, tirando nosso cálculo e validações
        // switch (this.proposta.formaCalculo) {
        //   case 'valorDoEmprestimo':
        //     this.calculando = true
        //     await this.calcularPeloValorDoEmprestimo(event)
        //     await this.recalcular()
        //     break
        //   case 'valorDaParcela':
        //     this.calculando = true
        //     await this.calcularPeloValorDaParcela(event)
        //     break
        // }

        // this.proposta.valorEmprestado = formatarValorReais(
        //   formatarParaNumber(this.proposta.valor) + formatarParaNumber(this.proposta.valorIOF),
        // )
        // if (this.proposta.compraDeDivida) {
        //   await this.calcularvalorRemanescente()
        // }
      },
      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularPeloValorDoEmprestimo(event) {
      //   try {
      //     const valor = formatarParaNumber(this.proposta.valor)
      //     if (parcelas < 0 || valor < 0) {
      //       return this.$message.error('Preencha o número de parcelas.')
      //     }

      //     const parametros = {
      //       idSegurado: this.segurado.id,
      //       institutoSelecionado: this.proposta.institutoSelecionado,
      //       valor,
      //       parcelas: this.proposta.parcelas,
      //       valorPrimeiraParcela: Number(valorPrimeiraParcela),
      //       campo: event.target.id,
      //       tipoCalculo: 'price',
      //       formaCalculo: this.proposta.formaCalculo,
      //       produtoId: this.proposta.produtoId,
      //     }
      //     await useJwt
      //       .post('utils/GetcalcularValoresSegurado', parametros)
      //       .then((response) => {
      //         this.proposta.valorPrimeiraParcela = response.data.valorPrimeiraParcela
      //         this.proposta.valorDoEmprestimo = this.formatarValorToText(response.data.valorDoEmprestimo)
      //         this.proposta.valorEfetivo = response.data.valor
      //         this.proposta.valorMaximoGeral = response.data.valorMaximoGeral
      //         this.proposta.taxa = Number(response.data.taxa)
      //         this.proposta.parcelaMaxima = response.data.parcelaMaxima
      //       })
      //       .catch((error) => {
      //         this.$message.error('Erro ao calcular empréstimo')
      //         this.calculando = false
      //         console.error(error)
      //       })
      //   } catch (error) {
      //     this.$message.error('Erro ao calcular empréstimo')
      //     this.calculando = false
      //     console.error(error)
      //   }
      // },
      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularPeloValorDaParcela() {
      //   try {
      //     const valorDaParcela = this.proposta.valorPrimeiraParcela

      //     const parametros = {
      //       utilizaFloatFinanceiro: this.produto.utilizaFloatFinanceiro,
      //       utilizaIOF: this.produto.utilizaIOF,
      //       parcelas: Number(this.proposta.parcelas),
      //       primeiraParcela: parse(this.proposta.primeiraParcela, 'dd/MM/yyyy', new Date()).toISOString(),
      //       valorParcela: formatarParaNumber(this.proposta.valorPrimeiraParcela),
      //       mesVencimento: Number(this.produto.mesVencimento),
      //       diaDeRepasse: Number(this.proposta.diaDeRepasse),
      //       diaFechamentoFolha: Number(this.proposta.diaFechamentoFolha),
      //       repassaNoMesmoMesDeVencimento: this.proposta.repassaNoMesmoMesDeVencimento ?? false,
      //       produtoId: this.proposta.produtoId,
      //     }
      //     await useJwt
      //       .post('proposta/calcularProposta', parametros)
      //       .then((response) => {
      //         this.calcularValorMaisProximoParcela(response.data, valorDaParcela)
      //       })
      //       .catch((error) => {
      //         this.$message.error('Erro ao calcular parcela')
      //         this.calculando = false
      //         console.error(error)
      //       })
      //   } catch (error) {
      //     this.$message.error('Erro ao calcular parcela')
      //     this.calculando = false
      //     console.error(error)
      //   }
      // },
      // Alteração da Genial, tirando nosso cálculo e validações
      // async recalcular() {
      //   let iof
      //   let float
      //   let valorIOF = 0

      //   if (this.produto.utilizaIOF) {
      //     iof = await this.calcularIOF()
      //     this.proposta.valorComIOF = formatarValorReais(iof.valorComIOF)
      //     this.proposta.parcelaComIOF = formatarValorReais(iof.parcelaComIOF)
      //     this.proposta.valorPrimeiraParcela = formatarParaNumber(iof.parcelaComIOF)
      //     this.proposta.valorIOF = formatarValorReais(iof.valorIOF)
      //     valorIOF = math.bignumber(formatarParaNumber(iof.valorIOF))
      //     this.proposta.taxaIOF = iof.taxaIOF
      //     this.proposta.quantidadeDiasIOF = iof.quantidadeDiasIOF
      //     this.proposta.valorBaseCalculo = iof.valorBaseCalculo
      //     this.proposta.valorDiarioIOF = iof.valorDiarioIOF
      //     this.proposta.valorEfetivoIOF = iof.valorEfetivoIOF
      //     this.proposta.taxaDiariaIOF = iof.taxaDiariaIOF
      //     this.proposta.taxaEfetivaIOF = iof.taxaEfetivaIOF
      //     this.proposta.valorTotalIOF = iof.valorTotalIOF
      //   }

      //   if (this.produto.utilizaFloatFinanceiro) {
      //     float = await this.calcularFloat()
      //     this.proposta.valorComFloat = formatarValorReais(float.valorComFloat)
      //     this.proposta.valorPrimeiraParcela = formatarParaNumber(float.parcelaComFloat)
      //     this.proposta.valorDoFloat = formatarValorReais(float.valorDoFloat)
      //   }

      //   let valorTotal = formatarParaNumber(this.proposta.valor)

      //   if (iof) valorTotal += iof.valorIOF
      //   if (float) valorTotal += float.valorDoFloat

      //   let valorPrimeiraParcela = arrendondarCalculo(this.proposta.valorPrimeiraParcela)

      //   this.proposta.valorPrimeiraParcela = formatarValor(valorPrimeiraParcela)

      //   this.proposta.valorTotal = formatarValor(valorTotal)
      //   this.calculoConcluido = true

      //   this.proposta.valorComIOF = formatarValor(
      //     Number(math.add(valorIOF, math.bignumber(formatarParaNumber(this.proposta.valor)))),
      //   )
      // },
      // Alteração da Genial, tirando nosso cálculo e validações
      // async gerarParcelas() {
      //   this.isBusy = true
      //   const saldoDevedor = this.produto.utilizaIOF
      //     ? formatarParaNumber(this.proposta.valorComIOF)
      //     : formatarParaNumber(this.proposta.valor)

      //   const valorFloat = this.produto.utilizaFloatFinanceiro ? formatarParaNumber(this.proposta.valorDoFloat) : 0

      //   // comment to commit
      //   const parcelasCalculadas = await useJwt
      //     .post('utils/GetcalcularParcelasSegurado', {
      //       idSegurado: this.proposta.seguradoSelecionado.id,
      //       institutoSelecionado: this.proposta.institutoSelecionado,
      //       produtoId: this.proposta.produtoId,
      //       parcelas: this.proposta.parcelas,
      //       taxa: this.proposta.taxa.toString(),
      //       taxaSpreadPosFixado: this.produto.taxaSpreadPosFixado,
      //       saldoDevedor: saldoDevedor,
      //       valorFloat: valorFloat,
      //       valorDaParcela: formatarParaNumber(this.proposta.valorPrimeiraParcela),
      //       tipoCalculo: 'price',
      //       formaCalculo: this.proposta.formaCalculo,
      //     })
      //     .then((response) => {
      //       this.itemsParcelas = response.data
      //       this.isBusy = false
      //       return response.data
      //     })
      //     .catch((error) => {
      //       this.$message.error('Erro ao gerar parcelas!')
      //       console.error(error)
      //     })

      //   for (const parcela of this.itemsParcelas) {
      //     if (parcela.taxaindiceInflacionarioPosFixado?.value) {
      //       parcela.taxaindiceInflacionarioPosFixado = parcela.taxaindiceInflacionarioPosFixado?.value
      //     }
      //     if (parcela.taxajurosAtuariaisPosFixado?.value) {
      //       parcela.taxajurosAtuariaisPosFixado = parcela.taxajurosAtuariaisPosFixado?.value
      //     }
      //     if (parcela.taxafundoGarantidorPosFixado?.value) {
      //       parcela.taxafundoGarantidorPosFixado = parcela.taxafundoGarantidorPosFixado?.value
      //     }
      //     if (parcela.taxataxaAdministrativaPosFixado?.value) {
      //       parcela.taxataxaAdministrativaPosFixado = parcela.taxataxaAdministrativaPosFixado?.value
      //     }

      //     parcela.valor = parcela.valor.toFixed(2)
      //   }

      //   const initialValue = 0
      //   this.valorTotalParcelas = this.itemsParcelas.reduce(
      //     (accumulator, currentValue) => Number(accumulator) + Number(currentValue.valor),
      //     initialValue,
      //   )
      //   this.valorTotalParcelas = formatarValor(formatarParaNumber(this.valorTotalParcelas).toFixed(2))
      //   this.totalRowsParcelas = parcelasCalculadas.length

      //   return {
      //     valorTotalParcelas: this.valorTotalParcelas,
      //     itemsParcelas: this.itemsParcelas,
      //     primeiraParcela: this?.itemsParcelas[0]?.valor,
      //   }
      // },
      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularValorMaisProximoParcela(resposta, valorDaParcela) {
      //   const dados = this.findClosestValue(resposta, valorDaParcela)

      //   this.proposta.valorPrimeiraParcela = dados.parcelaReal
      //   this.proposta.valorBaseCalculo = dados.valor

      //   const { iof } = dados
      //   const { float } = dados

      //   if (this.produto.utilizaIOF) {
      //     this.proposta.valorComIOF = formatarValorReais(iof.valorComIOF)
      //     this.proposta.parcelaComIOF = formatarValorReais(iof.parcelaComIOF)
      //     this.proposta.valorIOF = formatarValorReais(iof.iofDiario + iof.iofEfetivo)
      //     this.proposta.taxaIOF = iof.taxaIOF
      //   }

      //   if (this.produto.utilizaFloatFinanceiro) {
      //     this.proposta.valorComFloat = formatarValorReais(float.float + iof.valorComIOF)
      //     this.proposta.valorDoFloat = formatarValorReais(float.float)
      //   }

      //   this.proposta.valor = formatarValor(dados.valor)
      //   this.proposta.valorTotal = formatarValor(dados.valorTotal)
      //   this.proposta.valorPrimeiraParcela = formatarValor(dados.parcelaReal)
      // },
      adicionarMascaraCPF(value) {
        value = value.replace(/\D/g, '')
        if (value.length > 12) {
          value = value.slice(0, 12)
        }
        if (value.length > 3) {
          value = value.slice(0, 3) + '.' + value.slice(3)
        }
        if (value.length > 7) {
          value = value.slice(0, 7) + '.' + value.slice(7)
        }
        if (value.length > 11) {
          value = value.slice(0, 11) + '-' + value.slice(11, 13)
        }
        return value
      },
      fetchResults() {
        if (this.tipoBusca == '2') {
          this.proposta.segurado = this.adicionarMascaraCPF(this.proposta.segurado)
          const cpfSemMascara = this.proposta.segurado.replace(/\D/g, '')
          if (cpfSemMascara.length === 11) {
            const cpfValido = isCPFValid(cpfSemMascara)
            if (!cpfValido) {
              this.$message.error('Digite um cpf válido')
              return
            }
          }
        }
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (!this.proposta.segurado) {
            this.proposta = {
              ...this.proposta,
              seguradoSelecionado: null,
              segurado: null,
            }
            return
          }
          // let institutoId = this.userData.Instituto[0].id ?? this.proposta.institutoSelecionado;
          if (this.proposta.segurado.length >= 3) {
            useJwt
              .pesquisar('cadastro/tomador/GetSimulacaoPropostaSegurados', {
                descricao: this.proposta.segurado,
                produtoId: this.proposta.produtoId,
                tipoBusca: this.tipoBusca,
              })
              .then((response) => {
                this.suggestions = [{ data: response.data }]
              })
              .catch((error) => {
                console.error(error)
              })
          } else this.suggestions = []
        }, this.debounceMilliseconds)
      },
      async GetPesquisarIdadeCriteriosElegibilidade(item) {
        const dados = {
          produtoId: this.proposta.produtoId,
          categoria: this.segurado.categoria,
          idade: calcularIdade(item.tomador.dataNascimento),
        }
        await useJwt
          .post('cadastro/instituto/GetPesquisarIdadeCriteriosElegibilidade', dados)
          .then((response) => {
            this.idadePermitida = response.data?.length > 0
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async getSuggestionValue(suggestion) {
        const { item } = suggestion
        this.isBusySegurado = true
        if (item.diasDeCarenciaBloqueado) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.isBusySegurado = false
          this.$message.error('Bloqueado por período de carência')
          return
        }

        if (!item.grupoFolhaId) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.$swal({
            title: 'Atenção!',
            text: 'O segurado não possui grupo folha cadastrado.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        await this.GetPesquisarIdadeCriteriosElegibilidade(item)

        if (!this.idadePermitida) {
          this.proposta.segurado = ''
          this.suggestions = []
          this.$swal({
            title: 'Atenção!',
            text: 'O segurado não possui idade permitida para o empréstimo.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        if (!item.grupoFolha.diaDeRepasse) {
          this.proposta.segurado = ''
          this.suggestions = []

          this.$swal({
            title: 'Atenção!',
            text: 'O grupo folha não possui dia de repasse cadastrado.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.isBusySegurado = false
          return
        }

        const successMargem = await this.getMargemTomador(item)
        const isValidCustomer = this.validateCustomerData(item.tomador.id)

        if (!isValidCustomer || !isValidCustomer) {
          this.proposta.segurado = ''
          this.suggestions = []
          this.isBusySegurado = false
          return
        }

        if (!this.segurado.tomador.dataNascimento) this.campoSeguradoSelecionado = false
        this.exibeDadosSegurado = true
        this.segurado.id = item.id
        this.segurado.tomador.cpf = item.tomador.cpf
        this.segurado.tomador.nomeCompleto = item.tomador.nomeCompleto
        this.segurado.tomador.nomeSocial = item.tomador.nomeSocial
        this.segurado.tomador.dataNascimento = formatarData(item.tomador.dataNascimento)
        this.segurado.tomador.institutoId = item.tomador.institutoId
        this.segurado.categoria = item.situacaoPrevidenciaria
        this.segurado.remuneracaoProventos = formatarValor(item.remuneracao)
        this.segurado.margemUtilizada = formatarValor(item.margemUtilizada)
        this.segurado.margemDisponivel = successMargem ? formatarValor(successMargem) : formatarValor(item.margemDisponivel)
        this.proposta.seguradoSelecionado = item
        this.proposta.diaDeRepasse = item.grupoFolha.diaDeRepasse
        this.proposta.diaFechamentoFolha = item.grupoFolha.diaFechamentoFolha
        this.proposta.repassaNoMesmoMesDeVencimento = item.grupoFolha.repassaNoMesmoMesDeVencimento ?? false

        this.isBusySegurado = false

        return item.nomeCompleto
      },
      validationDadosSegurado() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tomRules.validate().then(async (success) => {
            if (success && this.segurado?.tomador?.nomeCompleto) {
              resolve(true)
            } else {
              let title
              if (!success) title = 'Alerta! Há campos não preenchidos no formulário.'
              else title = 'Alerta! Selecione o segurado na pesquisa para avançar.'
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationTipoCalculo() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.tipRules.validate().then(async (success) => {
            if (success) {
              this.calculando = false

              this.getParamsIofAndFloat()
              this.buscaValoresMaximos()

              // Alteração da Genial, tirando nosso cálculo e validações

              // useJwt
              //   .post('utils/GetcalcularValoresSegurado', {
              //     idSegurado: this.segurado.id,
              //     tipoCalculo: 'price',
              //     formaCalculo: this.proposta.formaCalculo,
              //     produtoId: this.proposta.produtoId,
              //   })
              //   .then((response) => {
              //     const values = {
              //       ...response.data,
              //       valor: undefined,
              //       valorPrimeiraParcela: undefined,
              //       parcelas: undefined,
              //     }
              //     this.proposta = {
              //       ...this.proposta,
              //       ...values,
              //     }
              //   })
              //   .catch((error) => {
              //     console.error(error)
              //     this.$swal({
              //       title: 'Atenção!',
              //       text: 'Segurado não elegível para o empréstimo.',
              //       icon: 'error',
              //       customClass: {
              //         confirmButton: 'btn btn-primary',
              //       },
              //       buttonsStyling: false,
              //     })
              //   })

              this.proposta.valorPrimeiraParcela = undefined
              this.proposta.valor = undefined
              this.proposta.parcelas = undefined
              this.proposta.valorIOF = undefined
              this.proposta.valorDaDivida = undefined
              this.proposta.bancoId = undefined
              this.$refs.dadosRules.reset()
              resolve(true)
            } else {
              const title = 'Alerta: Há campos não preenchidos no formulário.'
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      validationDadosConsignacao() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.dadosRules.validate().then(async (success) => {
            if (success && this.proposta.valor && this.proposta.parcelas && this.proposta.valorPrimeiraParcela) {
              // Alteração da Genial, tirando nosso cálculo e validações
              // await this.gerarParcelas()
              resolve(true)
            } else {
              const valorDigitado = this.proposta.valorPrimeiraParcela
              const valorMaximo = this.proposta.valorMaximoPrimeiraParcela
              const valorDigitadoFloat = parseFloat(valorDigitado?.replace(/\./g, '')?.replace(',', '.'))
              if (valorDigitadoFloat > valorMaximo) {
                const title = 'Alerta: O valor da parcela é maior que o permitido.'
                this.$message.error(title)
                reject()
              } else if (!success && (!this.proposta.valorPrimeiraParcela || !this.proposta.valor || !this.proposta.parcelas)) {
                this.$message.error('Erro: Há campos não preenchidos no formulário.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valor) < formatarParaNumber(this.produto.parametroValorMinimoEmprestimo)
              ) {
                this.$message.error('Existem valores inferiores ao parâmetro mínimo.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valorPrimeiraParcela) <
                  formatarParaNumber(this.produto.parametroValorMinimoParcela)
              ) {
                this.$message.error('Existem valores inferiores ao parâmetro mínimo.')
                reject()
              } else if (!success && !this.proposta.valorDaDivida) {
                this.$message.error('Campo valor da dívida é obrigatório.')
                reject()
              } else if (!success && !this.proposta.bancoId) {
                this.$message.error('Campo banco é obrigatório.')
                reject()
              } else if (
                !success &&
                formatarParaNumber(this.proposta.valorDaDivida) > formatarParaNumber(this.proposta.valor)
              ) {
                this.$message.error('Existem valores maiores ao parâmetro máximo.')
                reject()
              }
            }
          })
        })
      },
      validationTabelaParcelas() {
        return new Promise(async (resolve, reject) => {
          await this.$refs.bankRules.validate().then((success) => {
            if (success) {
              resolve(true)
            } else {
              const title = 'Erro: Há campos não preenchidos no formulário.'
              this.$message.error(title)
              reject()
            }
          })
        })
      },
      async salvarProposta() {
        try {
          // Exibe o loading no início do processo
          showLoading('Aguarde', 'Salvando proposta...')

          const { proposta: propostaAtual } = this
          const nomeNavegador = detectarNavegador()

          // Se a proposta já possuir um 'status', não prosseguimos
          if (propostaAtual.status) {
            this.loading = false
            await this.$swal({
              title: 'Atenção!',
              text: propostaAtual.error,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            closeLoading()
            return
          }

          // Monta objeto inicial com dados para salvar
          let dadosParaSalvar = {
            ...propostaAtual,
            valorBaseCalculo: formatarParaNumber(propostaAtual.valor),
            valorPrimeiraParcela: formatarParaNumber(this.itemsParcelas[0].valor),
            primeiraParcela: propostaAtual.primeiraParcela.split('/').reverse().join('-'),
            taxaDaCompetencia: this.taxaDaCompetencia,
            competenciaDaTaxa: this.competenciaDaTaxa,
            parcelas: parseInt(propostaAtual.parcelas),
            seguradoId: propostaAtual.seguradoSelecionado.id,
            institutoId: this.segurado.tomador.institutoId,
            nomeNavegador,
            tipoDeCalculoUtilizado: 'price',
            indiceUtilizado: this.produto.tipoIndiceSelecionado,
            itemsParcelas: this.itemsParcelas,
            origem: 'Administração',
          }

          // Adiciona ID do usuário logado à proposta
          dadosParaSalvar.usuarioId = this.userData.id

          // Se for compra de dívida, adiciona campos específicos
          if (propostaAtual.compraDeDivida) {
            dadosParaSalvar.compraDeDivida = propostaAtual.compraDeDivida
            dadosParaSalvar.valorDivida = propostaAtual.valorDaDivida
            dadosParaSalvar.valorRemanescente = propostaAtual.valorRemanescente
            dadosParaSalvar.nomeBanco = propostaAtual.nomeBanco
          }

          // Ajuste de valor se utiliza IOF
          if (this.produto.utilizaIOF) {
            const valorIOF = formatarParaNumber(propostaAtual.valorIOF)
            const valor = formatarParaNumber(propostaAtual.valor)
            dadosParaSalvar.valorComIOF = Number(math.add(math.bignumber(valor), math.bignumber(valorIOF)).toFixed(2))
            dadosParaSalvar.valor = dadosParaSalvar.valorComIOF
          }

          // Ajuste de valor se utiliza Float Financeiro
          // NOTE - Alteração da Genial, tirando valore de float
          // if (this.produto.utilizaFloatFinanceiro) {
          //   dadosParaSalvar.valor = formatarParaNumber(propostaAtual.valorComFloat)
          // }

          // Remove campos não necessários no envio
          const { segurado, seguradoSelecionado, ...restoDadosProposta } = dadosParaSalvar

          // Dados que serão enviados ao backend, formatados de acordo com a forma de cálculo
          let dadosFormatados = {}

          if (propostaAtual.formaCalculo === 'valorDaParcela') {
            dadosFormatados = {
              ...restoDadosProposta,
              valor: formatarParaNumber(restoDadosProposta.valor),
              valorComFloat: formatarParaNumber(restoDadosProposta.valorComFloat),
              valorComIOF: formatarParaNumber(restoDadosProposta.valorComIOF),
              valorIOF: formatarParaNumber(restoDadosProposta.valorIOF),
              parcelaComFloat: formatarParaNumber(restoDadosProposta.parcelaComFloat),
              parcelaComIOF: formatarParaNumber(restoDadosProposta.parcelaComIOF),
              valorPrimeiraParcela: Number(restoDadosProposta.valorPrimeiraParcela),
              valorFloat: formatarParaNumber(restoDadosProposta.valorDoFloat),
              idPropostaExterna: this.proposta.idPropostaExterna,
            }
          } else {
            dadosFormatados = {
              ...restoDadosProposta,
              valor: formatarParaNumber(restoDadosProposta.valor),
              valorComFloat: formatarParaNumber(restoDadosProposta.valorComFloat),
              valorComIOF: formatarParaNumber(restoDadosProposta.valorComIOF),
              valorIOF: formatarParaNumber(restoDadosProposta.valorIOF),
              parcelaComFloat: formatarParaNumber(restoDadosProposta.parcelaComFloat),
              valorFloat: formatarParaNumber(propostaAtual.valorDoFloat),
              produtoId: formatarParaNumber(propostaAtual.produtoId),
              usuarioId: formatarParaNumber(this.userData.id),
              idPropostaExterna: this.proposta.idPropostaExterna,
            }
          }

          // Ajuste da taxa final de acordo com o produto
          dadosFormatados.taxa = Number(this.produto.taxaEmprestimoMensal)

          // Envio ao endpoint
          await useJwt.post('proposta/novaProposta', dadosFormatados)

          // Caso dê tudo certo
          await this.$swal({
            title: 'Atenção!',
            text: 'Proposta gerada com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.loading = false
          this.$emit('atualizarGrid')
          this.$emit('closeSimulationModal')
          closeLoading()
        } catch (erro) {
          // Trata erros de requisição ou de fluxo
          this.loading = false
          console.error(erro)
          this.$message.error('Erro ao salvar proposta')
          closeLoading()
        }
      },

      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularIOF() {
      //   const parametros = {
      //     ...this.proposta,
      //     valor: formatarParaNumber(this.proposta.valor),
      //     valorComIOF: formatarParaNumber(this.proposta.valorComIOF),
      //     valorDoEmprestimo: formatarParaNumber(this.proposta.valor),
      //     taxaDiariaIOF: this.produto.aliquotaDiariaIOF,
      //     taxaEfetivaIOF: this.produto.aliquotaEfetivaIOF,
      //     produtoId: this.proposta.produtoId,
      //     parcelaComIOF: formatarParaNumber(this.proposta.parcelaComIOF),
      //     tipoCalculo: 'price',
      //   }

      //   const response = await useJwt.post('proposta/GetCalcularIOF', parametros)
      //   console.debug('IOF: ', response.data)

      //   const calculoIOf = {
      //     valorComIOF: response.data.valorComIOF,
      //     parcelaComIOF: response.data.parcelaComIOF,
      //     valorIOF: response.data.valorIOF,
      //     taxaIOF: response.data.taxaIOF,

      //     quantidadeDiasIOF: response.data.quantidadeDias,
      //     valorBaseCalculo: response.data.valorBaseCalculo,
      //     valorDiarioIOF: response.data.valorDiarioIOF,
      //     valorEfetivoIOF: response.data.valorEfetivoIOF,
      //     taxaDiariaIOF: response.data.taxaDiariaIOF,
      //     taxaEfetivaIOF: response.data.taxaEfetivaIOF,
      //     valorTotalIOF: response.data.valorTotalIOF,
      //   }
      //   return calculoIOf
      // },

      // Alteração da Genial, tirando nosso cálculo e validações
      // async calcularFloat() {
      //   const parametroFloat = {
      //     valor: this.produto.utilizaIOF
      //       ? formatarParaNumber(this.proposta.valorComIOF)
      //       : formatarParaNumber(this.proposta.valor),
      //     parcelas: formatarParaNumber(this.proposta.parcelas),
      //     taxa: this.proposta.taxa,
      //     diaDeRepasse: this.proposta.diaDeRepasse,
      //     diaFechamentoFolha: this.proposta.diaFechamentoFolha,
      //     repassaNoMesmoMesDeVencimento: this.proposta.repassaNoMesmoMesDeVencimento ?? false,
      //     instituto: {
      //       tipoVencimentoParcela: this.produto.tipoVencimentoParcela,
      //       diaFixoVencimentoParcela: this.produto.diaFixoVencimentoParcela,
      //       mesVencimento: this.produto.mesVencimento,
      //       taxaEmprestimoMensal: this.produto.taxaEmprestimoMensal,
      //       taxaSpreadPosFixado: this.produto.taxaSpreadPosFixado,
      //     },
      //     tipoCalculo: 'price',
      //     taxaSpreadPosFixado: this.produto.taxaSpreadPosFixado,
      //     produtoId: this.proposta.produtoId,
      //   }

      //   const response = await useJwt.post('proposta/GetCalcularFloat', parametroFloat)
      //   console.debug('FLOAT: ', response.data)

      //   const valoresFloat = {
      //     valorComFloat: response.data.valorComFloat,
      //     valorDoFloat: response.data.valorDoFloat,
      //     parcelaComFloat: response.data.parcelaComFloat,
      //   }

      //   return valoresFloat
      // },

      formatarValorToText(valor) {
        let valorString = String(valor)
        if (!valorString.includes('.')) {
          valorString = `${valorString}.00`
        }
        return valorString
      },
      preparaComponenteParaAplicativo() {
        this.proposta = {}
        const nomeTomador = String(this.userData.nomeCompleto).trim()

        // Consulta dados do tomador logado
        useJwt
          .pesquisar('cadastro/tomador/GetSimulacaoPropostaSegurados', {
            descricao: nomeTomador,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.segurado.id = response.data[0].id
            this.segurado.tomador.cpf = response.data[0].tomador.cpf
            this.segurado.tomador.nomeCompleto = response.data[0].tomador.nomeCompleto
            this.segurado.tomador.nomeSocial = response.data[0].tomador.nomeSocial
            this.segurado.tomador.dataNascimento = formatarData(response.data[0].tomador.dataNascimento)
            this.segurado.categoria = response.data[0].situacaoPrevidenciaria
            this.segurado.remuneracaoProventos = formatarValor(response.data[0].remuneracao)
            this.segurado.margemUtilizada = formatarValor(response.data[0].margemUtilizada)
            this.segurado.margemDisponivel = formatarValor(response.data[0].margemDisponivel)
            this.proposta.seguradoSelecionado = response.data[0]
            this.proposta.diaDeRepasse = response.data[0].grupoFolha.diaDeRepasse
            this.proposta.diaFechamentoFolha = response.data[0].grupoFolha.diaFechamentoFolha
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarListaDeProdutos() {
        useJwt
          .post('produto/getAllProducts', {})
          .then((response) => {
            this.listaProdutos = response.data.map((item) => {
              return {
                value: item.id,
                text: item.nome,
              }
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao carregar a lista de produtos!')
          })
      },
      async getParamsIofAndFloat() {
        try {
          const { data } = await useJwt.get('produto/get-parametros-nova-proposta/' + this.proposta.produtoId)

          this.produto.utilizaIOF = data.utilizaIOF
          this.produto.utilizaFloatFinanceiro = data.utilizaFloatFinanceiro
          this.produto.parametroValorMinimoParcela = data.valorMinimoParcela
          this.produto.parametroValorMinimoEmprestimo = data.valorMinimoEmprestimo

          this.produto.tipoIndiceSelecionado = data.tipoIndiceSelecionado
          this.produto.mesVencimento = data.mesVencimento
          this.produto.aliquotaDiariaIOF = data.aliquotaDiariaIOF
          this.produto.aliquotaEfetivaIOF = data.aliquotaEfetivaIOF
          this.produto.tipoVencimentoParcela = data.tipoVencimentoParcela
          this.produto.diaFixoVencimentoParcela = data.diaFixoVencimentoParcela
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao buscar parâmetros do produto!')
        }
      },
      async validateCustomerData(tomadorId) {
        return new Promise(async (resolve, reject) => {
          await useJwt
            .post('cadastro/tomador/getCustomerDataValidationForProposal', {
              tomadorId: tomadorId,
            })
            .then((response) => {
              if (response.data.isValid) {
                resolve(true)
              } else {
                const listaErros = response.data.erros

                this.$swal({
                  title: 'Atenção!',
                  html: `Para criar proposta é necessário preencher todos os dados do tomador. Lista de erros:<br><br>${listaErros.join(
                    '<br>',
                  )}`,
                  icon: 'warning',
                  confirmButtonText: 'Fechar',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                  buttonsStyling: false,
                })
                resolve(false)
              }
            })
            .catch((error) => {
              console.error(error)
              reject(false)
            })
        })
      },
      async getMargemTomador(item) {
        try {
          if (!item.orgao || !item.orgao.cnpj) {
            this.$swal({
              title: 'Atenção!',
              text: 'Orgão/CNPJ do órgao não foi cadastrado.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return false
          }
          const param = {
            matricula: item.matricula,
            cpf: desformatarCPF(item.tomador.cpf),
            cnpj: desformatarCNPJ(item.orgao.cnpj),
            tomadorId: item.tomador.id,
          }
          const response = await useJwt.post('genial/getMargemTomador', param)
          return response.data
        } catch (error) {
          const margem = await this.$swal({
            icon: 'warning',
            title: 'Digite a margem do segurado',
            html: `
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">R$</span>
                </div>
                <input
                  type="text"
                  id="valorInput"
                  class="form-control"
                  placeholder="Digite o valor aqui"
                />
              </div>
            `,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            confirmButtonText: 'Salvar',
            allowOutsideClick: false,
            preConfirm: () => {
              const inputElement = document.getElementById('valorInput')
              const rawValue = parseFloat(inputElement.dataset.rawValue || 0)
              if (!rawValue || rawValue <= 0) {
                this.$swal.showValidationMessage('Informe um valor maior que R$0,00!')
                return false
              }

              return rawValue
            },
            didOpen: () => {
              const inputElement = document.getElementById('valorInput')

              const formatCurrency = (value) => {
                const numericValue = value.replace(/\D/g, '')
                if (!numericValue) {
                  inputElement.dataset.rawValue = 0
                  return ''
                }
                const formattedValue = (numericValue / 100).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                inputElement.dataset.rawValue = numericValue / 100
                return formattedValue
              }

              inputElement.addEventListener('input', (e) => {
                const cursorPosition = inputElement.selectionStart
                const originalLength = inputElement.value.length

                inputElement.value = formatCurrency(inputElement.value)

                const newLength = inputElement.value.length
                inputElement.selectionStart = cursorPosition + (newLength - originalLength)
                inputElement.selectionEnd = cursorPosition + (newLength - originalLength)
              })
            },
          })

          const param = {
            margem: margem.value,
            tomadorId: item.tomador.id,
          }

          try {
            const response = await useJwt.post('cadastro/tomador/atualizarMargemTomador', param)

            if (response && response.status === 201) {
              this.$swal.fire({
                icon: 'success',
                title: 'Margem atualizada com sucesso!',
              })
              this.segurado.margemDisponivel = margem.value
              return margem.value
            } else {
              throw new Error('Erro ao atualizar a margem do tomador!')
            }
          } catch (error) {
            this.$message.error('Erro ao atualizar a margem do tomador!')
            return false
          }
        }
      },
      async buscaValoresMaximos() {
        try {
          this.calculando = true

          const parametro = {
            seguradoId: this.proposta.seguradoSelecionado.id,
            tomadorId: this.proposta.seguradoSelecionado.tomador.id,
            produtoId: this.proposta.produtoId,
          }

          const response = await useJwt.post('proposta/getValoresMaximosGenial', parametro)
          this.proposta.parcelaMaxima = response.data.quantidadeParcelaMaxima
          this.proposta.valorMaximoGeral = response.data.valorDeEmprestimoMaximo
          this.proposta.valorMaximoPrimeiraParcela = Number(response.data.valorParcelaMaxima)
          this.calculando = false
        } catch (error) {
          console.error(error)
          this.calculando = false
          if (error.response.data.stack.includes('Cliente já possui proposta em análise')) {
            this.$message.error('O tomador já possui proposta em análise!')
          } else {
            this.$message.error('Erro ao buscar valores máximos para o cálculo!')
          }
        }
      },
      async calcularComGenial() {
        this.calculando = true
        try {
          // Desestrutura os campos que serão usados
          const { seguradoSelecionado, produtoId, parcelas, formaCalculo, valor, valorPrimeiraParcela } = this.proposta

          // Monta o payload a ser enviado
          const payload = {
            seguradoId: seguradoSelecionado.id,
            tomadorId: seguradoSelecionado.tomador.id,
            produtoId,
            parcelas: formatarParaNumber(parcelas),
            formaCalculo,
          }

          // Ajusta o valor que será enviado no payload de acordo com a forma de cálculo
          if (formaCalculo === 'valorDaParcela') {
            payload.valorParcela = formatarParaNumber(valorPrimeiraParcela)
          } else {
            payload.valor = formatarParaNumber(valor)
          }

          // Faz a requisição
          const { data } = await useJwt.post('proposta/getCalculoGenial', payload)

          // Desestruturando dados da resposta
          const { amount, iofAmount, installments, id, monthCetRate, annualCetRate } = data

          // Cálculo do valor com IOF
          const valorComIOF = Number(math.add(math.bignumber(amount), math.bignumber(iofAmount)).toFixed(2))

          // Ajusta primeira parcela
          this.proposta.primeiraParcela = formatarData(installments[0].dueDate)

          // Mapeia as parcelas para a exibição
          this.itemsParcelas = installments.map(({ installmentNumber, dueDate, installmentAmount }) => ({
            parcela: installmentNumber,
            vencimento: dueDate,
            valor: installmentAmount,
          }))

          // Calcula o valor total das parcelas
          this.valorTotalParcelas = formatarValor(this.itemsParcelas.reduce((acc, { valor }) => acc + Number(valor), 0))

          // Atribui dados gerais à proposta
          Object.assign(this.proposta, {
            idPropostaExterna: id,
            CETMensal: monthCetRate,
            CETAnual: annualCetRate,
          })

          // Ajusta campos específicos conforme a forma de cálculo
          switch (formaCalculo) {
            case 'valorDaParcela':
              Object.assign(this.proposta, {
                valor: amount,
                valorComIOF,
                valorIOF: formatarValor(Number(Number(iofAmount).toFixed(2))),
                taxaIOF: null,
                valorComFloat: null,
                valorDoFloat: null,
                valorDaDivida: null,
              })
              break

            case 'valorDoEmprestimo':
              Object.assign(this.proposta, {
                valorPrimeiraParcela: formatarValor(installments[0].installmentAmount),
                valorComIOF,
                valorIOF: formatarValor(Number(Number(iofAmount).toFixed(2))),
                taxaIOF: null,
                valorComFloat: null,
                valorDoFloat: null,
              })
              break

            default:
              break
          }
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao calcular proposta!')
        } finally {
          this.calculando = false
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  pre {
    min-height: 295px;
    padding: 1.5rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }

  .autosuggest__results-item {
    cursor: pointer;
  }

  #radiosFormasDeCalculo,
  #radiosTipoDeCalculo {
    display: flex;
  }

  #radiosFormasDeCalculo label span {
    white-space: nowrap;
  }
</style>
