<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name || userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        v-show="userData.foto"
        :src="userData.foto"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
      <feather-icon size="24" icon="UserIcon" class="mr-100" v-show="!userData.foto" />
    </template>

    <b-dropdown-item
      :to="{ name: 'ConfiguracoesPerfil' }"
      link-class="d-flex align-items-center"
      v-if="acesso.ConfiguracoesSistemaPerfilAcessar"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-100" />
      <span style="padding-left: 7px">Perfil</span>
    </b-dropdown-item>

    <b-dropdown-divider v-if="acesso.ConfiguracoesSistemaPerfilAcessar" />

    <b-dropdown-item :to="{ name: 'centralapoio' }" link-class="d-flex align-items-center">
      <feather-icon size="20" icon="HelpCircleIcon" class="mr-100" />
      <span style="padding-left: 7px">Ajuda</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-100" />
      <span style="padding-left: 7px">Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import { initialAbility } from '@/libs/acl/config'
  import { avatarText } from '@core/utils/filter'
  import useJwt from '@/auth/jwt/useJwt'
  import cryptUserData from '@/@core/auth/utils/Crypt-User-Data'

  export default {
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        avatarText,
        foto: null,
        acesso: {
          ConfiguracoesSistemaPerfilAcessar: false,
        },
      }
    },
    async created() {
      if (this.userData) {
        this.$root.$on('atualizarFotoDropDown', (item) => {
          this.userData = this.$jwtDecode(localStorage.getItem('userData')).userData
          this.userData.foto = item
          cryptUserData(this.userData)
        })

        const response = await useJwt.get(`cadastro/user/${this.userData.id}/foto-perfil`)
        this.foto = response.data.foto
      }
    },
    async mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaPerfilAcessar = true
      } else {
        this.acesso.ConfiguracoesSistemaPerfilAcessar = this.userData.GrupoAcesso.ConfiguracoesSistemaPerfilAcessar
      }
      if (this.userData) {
        this.$root.$on('atualizarFotoDropDown', (item) => {
          this.userData = this.$jwtDecode(localStorage.getItem('userData')).userData
          this.userData.foto = item
          cryptUserData(this.userData)
        })
      }
    },
    methods: {
      logout() {
        // Set sigla for maintain in login
        const sigla = this.$jwtDecode(localStorage.getItem('userData')).userData?.Instituto?.sigla
        localStorage.setItem('sigla', sigla)

        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        localStorage.removeItem('images')
        sessionStorage.removeItem('itensCarregados')

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      },
    },
  }
</script>
