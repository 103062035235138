<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2" id="divCard">
        <b-card class="mb-0 card-lg" v-if="modoAcesso">
          <div class="container">
            <logo :base64="base64" />
            <titulos />
          </div>

          <div class="container">
            <b-row>
              <b-col cols="3">
                <BarraDeProgressoCalculadoraEmprestimo :currentStep="1" />
              </b-col>
              <b-col cols="9">
                <validation-observer ref="authServidorForm">
                  <b-form class="auth-login-form mt-2">
                    <b-form-group label="CPF" label-for="cpf">
                      <validation-provider #default="{ errors }" name="CPF" rules="required">
                        <cleave
                          id="cpf"
                          type="text"
                          placeholder="Digite aqui seu CPF"
                          v-model="dados.cpf"
                          :raw="false"
                          :options="options.cleaveCPF"
                          class="form-control"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Nome Completo" label-for="nomeCompleto">
                      <b-form-input
                        id="nomeCompleto"
                        type="text"
                        placeholder="Digite aqui seu Nome Completo"
                        v-model="dados.nomeCompleto"
                      />
                    </b-form-group>

                    <b-form-group label="E-mail" label-for="email">
                      <b-form-input id="email" type="text" placeholder="Digite aqui seu E-mail" v-model="dados.email" />
                    </b-form-group>

                    <b-form-group label="Telefone" label-for="telefone">
                      <validation-provider #default="{ errors }" name="Telefone" rules="required">
                        <b-input-group v-if="dados.tipoContrato === 'novo'" class="input-group-merge">
                          <cleave
                            id="telefone2"
                            type="text"
                            placeholder="Digite aqui seu Telefone"
                            :options="options.cleavePhoneMask"
                            v-model="dados.telefone"
                            class="form-control"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>
                <b-form-checkbox id="checkAceite" v-model="aceite" name="checkAceite" value="true" unchecked-value="false">
                  Aceito que meus dados sejam utilizados para pesquisas ou eventuais contatos.
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>

          <b-row class="mt-4">
            <b-col cols="6" class="d-flex justify-content-left">
              <b-button variant="secondary" class="mt-2" @click="voltarTelaInicial">Voltar</b-button>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
              <b-button
                variant="primary"
                class="mt-2"
                @click="validationForm"
                @keypress.enter="validationForm"
                :disabled="aceite != 'true'"
              >
                Avançar
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-0 card-lg" v-if="!modoAcesso">
          <validation-observer ref="calculadoraForm">
            <b-form class="auth-login-form mt-2">
              <logo />
              <titulos />
              <b-container>
                <b-row>
                  <b-col cols="3">
                    <BarraDeProgressoCalculadoraEmprestimo :currentStep="2" />
                  </b-col>
                  <b-col cols="9">
                    <div class="container-valor">
                      <div class="mb-2">
                        <h3 class="text-titulo">De quanto você precisa?</h3>
                        <h4 class="text-valor">{{ formatarValor(valorEmprestimo) }}</h4>
                        <input
                          type="range"
                          v-model="valorEmprestimo"
                          :min="minValueValor"
                          :max="valorMaximoEmprestimo"
                          :step="stepCalcula"
                          @input="calcular"
                        />
                        <div class="d-flex justify-content-between">
                          <span>{{ minValueValor | formatCurrency }}</span>
                          <span>{{ valorMaximoEmprestimo | formatCurrency }}</span>
                        </div>
                      </div>
                      <div class="alinhamento">
                        <h3 class="text-titulo">Em quantos meses quer pagar?</h3>
                        <h4 class="text-valor">{{ quantidadeParcelas | formatMonths }}</h4>
                        <input
                          type="range"
                          v-model="quantidadeParcelas"
                          :min="minValueMeses"
                          :max="limiteParcelas"
                          :processStyle="slider.processStyle"
                          :lineHeight="slider.lineHeight"
                          :tooltipStyles="slider.tooltipStyles"
                          @input="calcular"
                        />
                        <div class="d-flex justify-content-between">
                          <span>{{ minValueMeses | formatMonths }}</span>
                          <span>{{ limiteParcelas | formatMonths }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="container-resultado text-center">
                      <b-row>
                        <b-col cols="6">
                          <p class="titulo">Parcela mensal</p>
                          <h3 class="valor">
                            {{ dados.valorDaParcela | formatCurrency }}<sup class="referencia">{{ quantidadeParcelas }}x</sup>
                          </h3>
                        </b-col>
                        <b-col cols="6">
                          <p class="titulo">Taxa</p>
                          <h3 class="valor">{{ taxaMensal }}%</h3>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1">
                        <b-col cols="12">
                          <p class="titulo">Valor total</p>
                          <h3 class="valor">{{ (dados.valorDaParcela * quantidadeParcelas) | formatCurrency }}</h3>
                        </b-col>
                      </b-row>
                      <hr class="custom-line" />

                      <b-row>
                        <b-col>
                          <p class="textAlt">Valores próximos ao real, sujeitos a variações.</p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-4">
                  <b-col cols="6" class="d-flex justify-content-left">
                    <b-button class="mt-2" variant="secondary" :disabled="loading" @click="limparDados()">
                      {{ !loading ? 'Voltar' : '' }}
                      <b-spinner small v-if="loading" />
                    </b-button>
                  </b-col>
                  <b-col cols="6" class="d-flex justify-content-end">
                    <b-button class="mt-2" variant="primary" @click="salvar"> Finalizar </b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import VueSlideBar from 'vue-slide-bar'
  import logo from './Componentes/logo.vue'
  import titulos from './Componentes/titulos.vue'
  import BarraDeProgressoCalculadoraEmprestimo from './Componentes/barraDeProgressoCalculadoraEmprestimo.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, valor } from '@validations'
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import {
    formatarDataWTime,
    formatarData,
    formatarValor,
    formatarParaNumber,
    formatarCPF,
    censurarCPF,
    calcularDiasEntreDatas,
    formatarDataGlobal,
  } from '@/libs/utils'
  import VueRecaptcha from 'vue-recaptcha'
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import 'sweetalert2/src/sweetalert2.scss'
  import * as math from 'mathjs'

  export default {
    components: {
      logo,
      VueSlideBar,
      titulos,
      BarraDeProgressoCalculadoraEmprestimo,
      VueRecaptcha,
      ValidationObserver,
      ValidationProvider,
      Cleave,
      formatarData,
      formatarValor,
      formatarParaNumber,
      formatarCPF,
      censurarCPF,
      required,
      math,
    },
    data() {
      return {
        base64: null,
        valorEmprestimo: 150,
        quantidadeParcelas: 1,
        minValueValor: 150,
        maxValueValor: 30000,
        minValueMeses: 1,
        maxValueMeses: 48,
        slider: {
          lineHeight: 5,
          processStyle: {
            backgroundColor: '#016b3a',
            borderRadius: '5px',
          },
          tooltipStyles: {
            backgroundColor: '#016b3a',
            borderColor: '#016b3a',
            borderRadius: '5px',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
          },
        },
        userData: {
          id: null,
          email: null,
          name: null,
          password: null,
          avatar: null,
          genero: null,
          bio: null,
          dataNascimento: null,
          pais: null,
          telefone: null,
          cargo: null,
          role: null,
          tipoAssinatura: null,
          institutoId: null,
          grupoAcessoId: null,
          ativo: null,
          createdAt: null,
          updatedAt: null,
          foto: null,
          Instituto: {
            id: null,
            nome: null,
            razaoSocial: null,
            cnpj: null,
            sigla: null,
            nomeResponsavel: null,
            cpfResponsavel: null,
            cargoResponsavel: null,
            dtInicioGestao: null,
            emailResponsavel: null,
            telefone: null,
            cep: null,
            logradouro: null,
            bairro: null,
            numero: null,
            complemento: null,
            uf: null,
            cidade: null,
            tipoIndiceSelecionado: null,
            ambienteApi: null,
            taxaAdministrativa: null,
            taxaOscilacaoRiscos: null,
            taxaEmprestimoAnual: null,
            taxaFundoGarantia: null,
            taxaDeOportunidade: null,
            taxaIndiceInflacionario: null,
            taxaEmprestimoMensal: null,
            jurosAtuarial: null,
            limitadorIdade: null,
            assinaturaDigital: null,
            limiteAlocacao: null,
            limiteParcelas: null,
            margemConsignavel: null,
            capag: null,
            tempoCarencia: null,
            bloqueioConsignacao: null,
            dataLimiteRecorte: null,
            tipoVencimentoParcela: null,
            diaFixoVencimentoParcela: null,
            mesVencimento: null,
            crpValidade: null,
            crpStatus: null,
            tabelaDeCalculo: null,
            limitadorEmprestimos: null,
            limitadorContratos: null,
            grpeIndiceAtualizacao: null,
            grpeJurosAposVencimento: null,
            grpeTipoJuros: null,
            grpeMulta: null,
            grpeMultaApos: null,
            grpeDiaVencimento: null,
            renegociacaoIndiceAtualizacao: null,
            renegociacaoJurosAposVencimento: null,
            renegociacaoTipoJuros: null,
            renegociacaoMulta: null,
            renegociacaoMultaApos: null,
            renegociacaoTipoIncidenciaJuros: null,
            boletoIndiceAtualizacao: null,
            boletoJurosAposVencimento: null,
            boletoTipoJuros: null,
            boletoMulta: null,
            boletoMultaApos: null,
            boletoBancoEmissor: null,
            boletoDiaUtil: null,
            createdAt: null,
            updatedAt: null,
            logo: null,
          },
          GrupoAcesso: null,
          ability: [
            {
              action: null,
              subject: null,
            },
          ],
          institutoSelecionado: null,
        },
        formatarValor,
        formatarData,
        modoAcesso: true,
        gerouCalculo: false,
        apresentaParcelas: false,
        logo: '',
        number: 0.0,
        formatarDataWTime,
        formatarCPF,
        loading: false,
        dados: {
          nomeCompleto: null,
          matricula: null,
          email: null,
          telefone: null,
          cpfCensurado: null,
          tipoCalculo: 'price',
          tipoContrato: 'novo',
          valorDaParcela: 0,
          quantidadeParcelas: 0,
        },
        aceite: false,

        // IOF e FLOAT
        utilizaFloat: false,
        utilizarIOF: true,

        // Parametros
        mesVencimento: null,
        limiteParcelas: null,
        valorMaximoEmprestimo: null,
        diaDoRepasse: null,
        diaFechamentoFolha: null,
        diaVencimentoParcela: null,

        // Parcela
        sortByParcelas: '',
        sortDescParcelas: false,
        sortDirectionParcelas: 'asc',
        perPageParcelas: 6,
        currentPageParcelas: 1,
        fieldsParcelas: ['parcela', 'valor'],
        totalRowsParcelas: 0,
        itemsParcelas: [],
        valorTotalParcelas: 0,
        valorIOFPorParcela: 0,
        valorFloatPorParcela: 0,
        primeiraParcela: null,
        ultimaParcela: null,
        dataHoje: null,
        dadosIOF: {},
        dadosFloat: {},
        stepCalcula: 850,

        options: {
          cleavePhone: {
            numericOnly: true,
            delimiters: ['(', ')', ' ', '-'],
            blocks: [0, 2, 0, 5, 4],
          },
          cleavePhoneMask: {
            numericOnly: true,
            delimiters: ['(', ')', ' ', '-'],
            blocks: [0, 2, 0, 5, 4],
          },
          cleaveCPF: {
            numericOnly: true,

            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveParcelas: {
            numericOnly: true,
            blocks: [2],
          },
          cleaveCurrency: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            delimiter: '.',
            numeralDecimalMark: ',',
            numeralDecimalScale: 2,
          },
        },
        statusTipoCalculo: {
          ambas: [
            { text: 'PRICE', value: 'price' },
            { text: 'SAC', value: 'sac' },
          ],
          price: [{ text: 'PRICE', value: 'price' }],
          sac: [{ text: 'SAC', value: 'sac' }],
        },
        optionsTipoCalculo: [
          { text: 'PRICE', value: 'price' },
          { text: 'SAC', value: 'sac' },
        ],
        vinculoOpcoesCalculo: false,
        optionsTipoContrato: [
          { text: 'Novo', value: 'novo' },
          { text: 'Portabilidade', value: 'portabilidade' },
        ],
        instituto: [],
        mensalTaxa: '',
        cpf: '',
        segurado: {},
        sugestoes: [],
        timeout: null,
        mensagemCampoCPF: '',
        siglaParam: '',
        apresentaRecaptcha: false,
        bloqueiaCalcular: false,
        captchaLoaded: false,

        stepData: [
          { label: 'Suas Informações', isActive: true },
          { label: 'Simular Valores', isActive: false },
        ],
      }
    },
    filters: {
      formatCurrency(value) {
        // Ajuste de acordo com o formato de moeda desejado
        return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      },
      formatMonths(value) {
        return value === 1 ? `${value} Mês` : `${value} Meses`
      },
    },
    props: {
      sigla: String,
    },
    watch: {
      dados(novo, atual) {
        if (novo.cpf?.length < 14) {
          this.mensagemCampoCPF = ''
        }
        if (novo.cpf?.length === 13 && atual.cpf?.length === 14) {
          this.dados.cpf = this.cpf.substring(this.cpf.length - 1)
        }
      },
    },
    async mounted() {
      this.siglaParam = new URLSearchParams(window.location.search).get('sigla')
      if (!this.siglaParam) this.siglaParam = window.location.href.toLowerCase().split('?sigla=')[1]
      this.buscaParametrosCalculadora(this.siglaParam)
      this.loadCaptcha()
    },
    methods: {
      async loadCaptcha() {
        if (document.getElementById('recaptcha-script') && this.captchaLoaded) return

        const script = document.createElement('script')
        script.id = 'recaptcha-script'
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LcvT_spAAAAAG9MhZiCt_M8WfAN_NMRvhR8iam0'
        script.async = true
        script.defer = true

        let _this = this

        const loadScript = new Promise((resolve, reject) => {
          script.onload = () => {
            _this.captchaLoaded = true
            resolve()
          }
          script.onerror = () => reject(new Error('Failed to load reCAPTCHA script'))
        })

        document.head.appendChild(script)

        await loadScript
        return
      },
      async getCaptchaToken() {
        await this.loadCaptcha()
        await new Promise((resolve) => window.grecaptcha.ready(resolve))
        const token = await window.grecaptcha.execute('6LcvT_spAAAAAG9MhZiCt_M8WfAN_NMRvhR8iam0', { action: 'submit' })
        return token
      },
      async validationForm() {
        this.$refs.authServidorForm.validate().then(async (success) => {
          const token = await this.getCaptchaToken()

          if (success && token) {
            this.modoAcesso = false
          }
        })
      },
      calcular() {
        if (this.valorEmprestimo != 150) {
          this.stepCalcula = 1000
          this.minValueValor = 0
        } else {
          this.stepCalcula = 850
          this.minValueValor = 150
        }
        this.$refs.calculadoraForm.validate().then((success) => {
          this.calcularDataParcelas()
          if (success) {
            this.valorTotalParcelas = 0
            this.loading = true
            if (this.dados.tipoCalculo == 'price') {
              this.calculoPrice()
            } else if (this.dados.tipoCalculo == 'sac') {
              this.calculoSAC()
            }
            this.bloqueiaCalcular = true
          }
        })
      },
      calcularDataParcelas() {
        // Data de hoje
        let hoje = new Date()

        // Ajusta o mês de vencimento com base na data atual e no mês de vencimento desejado
        let anoInicial = hoje.getFullYear()
        let mesInicial = hoje.getMonth() + this.mesVencimento // Pode ultrapassar 11, indicando um ano futuro

        // Corrige o ano e o mês, caso o mês de vencimento ultrapasse dezembro
        let anoPrimeiraParcela = anoInicial + Math.floor(mesInicial / 12)
        let mesPrimeiraParcela = mesInicial % 12

        // Tenta criar a data da primeira parcela
        let primeiraParcelaData = new Date(anoPrimeiraParcela, mesPrimeiraParcela, this.diaVencimentoParcela)

        // Corrige se o dia for ajustado automaticamente para o próximo mês (por exemplo, 31 de abril ajustado para 1 de maio)
        if (primeiraParcelaData.getMonth() !== mesPrimeiraParcela) {
          // Ajusta para o último dia do mês correto
          primeiraParcelaData = new Date(anoPrimeiraParcela, mesPrimeiraParcela + 1, 0)
        }

        // Calcula a data da última parcela
        let mesUltimaParcela = mesPrimeiraParcela + this.quantidadeParcelas - 1
        let anoUltimaParcela = anoPrimeiraParcela + Math.floor(mesUltimaParcela / 12)
        mesUltimaParcela = mesUltimaParcela % 12

        let ultimaParcelaData = new Date(anoUltimaParcela, mesUltimaParcela, this.diaVencimentoParcela)

        // Corrige se o dia da última parcela for ajustado para o próximo mês
        if (ultimaParcelaData.getMonth() !== mesUltimaParcela) {
          // Ajusta para o último dia do mês correto
          ultimaParcelaData = new Date(anoUltimaParcela, mesUltimaParcela + 1, 0)
        }

        let data = formatarDataGlobal(new Date())

        primeiraParcelaData.setHours(0, 0, 0, 0)
        ultimaParcelaData.setHours(0, 0, 0, 0)
        if (primeiraParcelaData.getHours() > 0) primeiraParcelaData.setHours(primeiraParcelaData.getHours() - 4)
        if (ultimaParcelaData.getHours() > 0) ultimaParcelaData.setHours(ultimaParcelaData.getHours() + 19)

        // Atualiza as propriedades da instância Vue
        this.primeiraParcela = primeiraParcelaData
        this.ultimaParcela = ultimaParcelaData
        this.dataHoje = data
      },

      async calculoIOF() {
        const valorBaseCalculo = formatarParaNumber(this.valorEmprestimo)
        const dataAtual = this.dataHoje
        const dataUltimaParcela = this.ultimaParcela
        const quantidadeDeDias = calcularDiasEntreDatas(dataAtual, dataUltimaParcela)
        const taxaIOFDiario = 0.000082
        const taxaAdicional = 0.0038

        let jurosDiarios = Number(quantidadeDeDias * taxaIOFDiario)
        jurosDiarios = Number(jurosDiarios) > 0.03 ? 0.03 : jurosDiarios

        const valorTaxa = Number(jurosDiarios + taxaAdicional)

        const valorIOF = Number(Number(valorBaseCalculo * valorTaxa).toFixed(2))

        const dadosOperacao = {}

        // const parcelaComIOF = await this.calcularParcelaParaIofOuFloat(this.tipoCalculo, dadosOperacao)

        const dadosIOF = {
          valorIOF: valorIOF,
        }

        this.dadosIOF = dadosIOF
        console.debug('parametrosIOF', {
          valorBaseCalculo,
          dataAtual,
          dataUltimaParcela,
          quantidadeDeDias,
          taxaIOFDiario,
          taxaAdicional,
          valorTaxa,
          valorIOF,
          dadosOperacao,
        })
        return dadosIOF
      },
      // calcularParcelaParaIofOuFloat() {
      //   return 250.0
      // },
      async calculoFLoat() {
        let valorEmprestimo = this.utilizaFloat
          ? formatarParaNumber(this.valorEmprestimo) + formatarParaNumber(this.dadosIOF?.valorIOF)
          : formatarParaNumber(this.valorEmprestimo)
        const diaDeRepasse = this.diaDoRepasse
        const dataPrimeiraParcela = this.primeiraParcela
        const mesVencimento = formatarParaNumber(this.mesVencimento)
        const diaFechamentoFolha = this.diaFechamentoFolha
        const diaVencimentoInicial = this.buscaDataDoRepasseDaPrimeiraParcela(mesVencimento, diaDeRepasse, diaFechamentoFolha)
        const quantidadeDias = calcularDiasEntreDatas(new Date(), diaVencimentoInicial) + 2

        let escopo
        escopo = { taxa: Number(math.divide(math.bignumber(formatarParaNumber(this.taxaMensal)), 100)) }

        let taxaDiaria = Number(math.multiply(math.evaluate(`( (1 + taxa) ^ ( 1 / 30 ) -1 )`, escopo), 100))

        let taxasPorDia = math.multiply(quantidadeDias, math.divide(taxaDiaria, 100))
        let valorDoFloat = math.multiply(valorEmprestimo, taxasPorDia)
        valorDoFloat = Number(Number(valorDoFloat).toFixed(2))

        const dadosFloat = {
          valorDoFloat: valorDoFloat,
        }

        this.dadosFloat = dadosFloat
        console.debug('parametrosFloat', {
          taxa: this.taxaMensal,
          valorEmprestimo,
          diaDeRepasse,
          dataPrimeiraParcela,
          mesVencimento,
          diaFechamentoFolha,
          diaVencimentoInicial,
          quantidadeDias,
          escopo,
          taxaDiaria,
          taxasPorDia,
          valorDoFloat,
        })
        return dadosFloat
      },
      calculoPrice() {
        let itemsParcelas = []
        let i = 1
        let valorAmortizacao = 0.0
        let saldoDevedor = formatarParaNumber(this.valorEmprestimo)
        let quantidadeParcelas = formatarParaNumber(this.quantidadeParcelas)
        let taxa = this.mensalTaxa
        taxa = taxa / 100

        let saldoDevedorCalculo = saldoDevedor
        let taxaCalculo = taxa
        let quantidadeParcelasCalculo = formatarParaNumber(this.quantidadeParcelas)

        console.debug('Parametros de calculo', {
          saldoDevedor,
          quantidadeParcelas,
          taxa,
          saldoDevedorCalculo,
          taxaCalculo,
          quantidadeParcelasCalculo,
          calculoIOF: this.utilizarIOF,
          calculoFloat: this.utilizaFloat,
        })

        if (this.utilizarIOF) {
          this.calculoIOF()
        }

        if (this.utilizaFloat) {
          this.calculoFLoat()
        }

        saldoDevedorCalculo = saldoDevedorCalculo + this.dadosIOF?.valorIOF + this.dadosFloat?.valorDoFloat
        // Fórmula tabela price sobre valor das parcelas:
        // Prestação Mensal = (Valor do Empréstimo x Taxa de Juros Mensal x (1 + Taxa de Juros Mensal) ^ Número de Parcelas) / ((1 + Taxa de Juros Mensal) ^ Número de Parcelas - 1)
        let valorDaParcela =
          (saldoDevedorCalculo * taxaCalculo * Math.pow(1 + taxaCalculo, quantidadeParcelasCalculo)) /
          (Math.pow(1 + taxaCalculo, quantidadeParcelasCalculo) - 1)

        valorDaParcela = valorDaParcela
        while (i <= quantidadeParcelas) {
          const juros = saldoDevedor * taxa
          valorAmortizacao = valorDaParcela - juros

          itemsParcelas.push({
            parcela: i,
            valor: Number(Number(valorDaParcela).toFixed(2)), //valorAmortizacao + juros,
          })
          this.valorTotalParcelas += Number(valorDaParcela)
          saldoDevedor -= valorAmortizacao

          i++
        }

        this.valorTotalParcelas = this.valorTotalParcelas //Number(Number(this.valorTotalParcelas).toFixed(2))
        this.dados.valorDaParcela = formatarParaNumber(Number(valorDaParcela).toFixed(2))
        this.itemsParcelas = itemsParcelas
        this.totalRowsParcelas = this.itemsParcelas.length
        this.loading = false
        this.apresentaParcelas = true
      },
      calculoSAC() {
        let itemsParcelas = []
        let i = 1
        let saldoDevedor = formatarParaNumber(this.dados.valorEmprestimo)
        let quantidadeParcelas = formatarParaNumber(this.quantidadeParcelas)
        let taxa = this.mensalTaxa
        taxa = taxa / 100

        if (this.utilizarIOF) {
          this.calculoIOF()
        }

        if (this.utilizaFloat) {
          this.calculoFLoat()
        }

        saldoDevedor = saldoDevedor + this.dadosIOF?.valorIOF + this.dadosFloat?.valorDoFloat

        // NOTE: Monta valores gerais
        let jurosPrimeiraParcela = saldoDevedor * taxa
        let valorAmortizacao = saldoDevedor / quantidadeParcelas
        let valorPrimeiraParcela = valorAmortizacao + jurosPrimeiraParcela

        // NOTE: Monta parcelas
        let valorAmortizacaoParcela = saldoDevedor / quantidadeParcelas

        let valorDaParcela = 0
        while (i <= quantidadeParcelas) {
          const juros = saldoDevedor * taxa
          valorDaParcela = Number((valorAmortizacaoParcela + juros).toFixed(2))

          itemsParcelas.push({
            parcela: i,
            valor: Number(Number(valorDaParcela).toFixed(2)),
          })
          this.valorTotalParcelas += Number(Number(valorDaParcela).toFixed(2))

          saldoDevedor = saldoDevedor - valorAmortizacaoParcela
          i++
        }

        this.itemsParcelas = itemsParcelas
        this.valorTotalParcelas = Number(Number(this.valorTotalParcelas).toFixed(2))
        this.dados.valorDaParcela = Number(Number(valorDaParcela).toFixed(2))
        this.totalRowsParcelas = this.itemsParcelas.length
        this.loading = false
        this.apresentaParcelas = true
        this.salvar()
      },
      limparDados() {
        this.dados = {
          nomeCompleto: null,
          email: null,
          telefone: null,
          valorEmprestimo: 150,
          tipoContrato: 'novo',
          tipoCalculo: 'price',
          quantidadeParcelas: 1,
        }
        this.valorTotalParcelas = 0
        this.itemsParcelas = 0
        this.totalRowsParcelas = 0
        this.modoAcesso = true
        this.apresentaParcelas = false
        this.gerouCalculo = false
        this.recaptchaOk = false
        this.aceite = 'false'
        this.loading = false
        this.apresentaRecaptcha = false
        this.bloqueiaCalcular = false
        this.valorEmprestimo = 150
        this.quantidadeParcelas = 1
        this.dados.valorDaParcela = null
        this.dadosIOF = {}
        this.dadosFloat = {}
        this.primeiraParcela = null
        this.ultimaParcela = null
      },
      reiniciarCalculadora() {
        this.dados = {
          ...this.dados,
          valorEmprestimo: null,
          quantidadeParcelas: null,
          valorDaParcela: null,
        }
        this.$refs.calculadoraForm.reset()
        this.bloqueiaCalcular = false
      },
      salvar() {
        let dados = {
          ...this.dados,
          taxaMensal: String(this.taxaMensal),
          tipoContrato: 'Novo',
          sigla: this.siglaParam,
          valorEmprestimo: this.valorEmprestimo,
          tipoCalculo: this.dados.tipoCalculo,
          quantidadeParcelas: this.quantidadeParcelas,
        }

        delete dados.cpfCensurado
        delete dados.matricula
        if (dados.margemDisponivel) delete dados.margemDisponivel

        useJwt
          .post('calculadoraEmprestimo/NovaConsultaCalculadora', dados)
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Simulação Finalizada!',
              showConfirmButton: true,
              confirmButtonColor: '#016b3a',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then(() => {
              this.limparDados()
              goto('/calculadoraEmprestimo')
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      voltarTelaInicial() {
        let sigla = localStorage.getItem('sigla')
        if (!sigla) localStorage.setItem('sigla', this.siglaParam)
        this.$router.push({ name: 'auth-login' })
      },
      buscaParametrosCalculadora(sigla) {
        useJwt
          .get(`calculadoraEmprestimo/GetParametrosCalculadora/${sigla}`)
          .then((res) => {
            if (res.data.length > 0) {
              this.limiteParcelas = res.data[0]?.calculadoraExternaParametro.limiteParcelas
              this.diaDoRepasse = res.data[0]?.calculadoraExternaParametro.diaDoRepasse
              this.diaFechamentoFolha = res.data[0]?.calculadoraExternaParametro.diaFechamentoFolha
              this.valorMaximoEmprestimo = formatarParaNumber(res.data[0]?.calculadoraExternaParametro.valorMaximoEmprestimo)
              this.taxaMensal = res.data[0]?.taxaEmprestimoMensal
              this.mensalTaxa = res.data[0]?.taxaEmprestimoMensal
              this.utilizarIOF = res.data[0]?.utilizaIOF
              this.utilizaFloat = res.data[0]?.utilizaFloatFinanceiro
              this.mesVencimento = res.data[0]?.mesVencimento
              if (res.data[0].tipoCalculoPrice && !res.data[0].tipoCalculoSAC) {
                this.dados.tipoCalculo = 'price'
              } else if (res.data[0].tipoCalculoSAC && !res.data[0].tipoCalculoPrice) {
                this.dados.tipoCalculo = 'sac'
              } else if (res.data[0].tipoCalculoPrice && res.data[0].tipoCalculoSAC) {
                this.dados.tipoCalculo = 'ambas'
              }
              const hoje = new Date()
              if (res.data[0].tipoVencimentoParcela == 'diaFixo') {
                this.diaVencimentoParcela = res.data[0].diaFixoVencimentoParcela
              } else if (res.data[0].tipoVencimentoParcela == 'diaContrato') {
                this.diaVencimentoParcela = Number(hoje.getDate())
              } else if (res.data[0].tipoVencimentoParcela == 'ultimoDia') {
                this.diaVencimentoParcela = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate()
              }

              this.base64 = res.data[0]?.logoCalculadora?.logo !== undefined ? res.data[0]?.logoCalculadora?.logo : null
            } else {
              this.limitadorIdade = null
              console.error('Falha ao consultar parametros da calculadora')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      buscaDataDoRepasseDaPrimeiraParcela(mesVencimento, diaDeRepasse, diaFechamentoFolha) {
        const hoje = new Date() // Supondo que seja 27/12/2023, mas usaremos a data atual
        let dataVencimento = new Date(hoje.getFullYear(), hoje.getMonth() + mesVencimento, diaFechamentoFolha)

        // Se mesVencimento for 0 e a data de vencimento for antes de hoje, adicione um mês
        if (mesVencimento === 0 && dataVencimento < hoje) {
          dataVencimento.setMonth(dataVencimento.getMonth() + 1)
        }

        // Encontrar o próximo dia de repasse
        let dataRepasse = new Date(dataVencimento.getFullYear(), dataVencimento.getMonth(), diaDeRepasse)
        if (dataRepasse < dataVencimento) {
          dataRepasse.setMonth(dataRepasse.getMonth() + 1)
        }

        return dataRepasse
      },
      onRecaptchaVerify() {
        this.recaptchaOk = true
        this.modoAcesso = false
      },
      expired() {
        this.recaptchaOk = false
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  h3 {
    margin-bottom: unset;
  }

  .referencia {
    top: -1em;
    right: -0.3em;
    position: relative;
    font-size: 0.5em; /* Tamanho menor para parecer um índice */
    background-color: white; /* Cor de fundo azul para combinar com o seu design */
    color: var(--primary); /* Letra branca */
    border-radius: 50%; /* Deixa a div redonda */
    width: 1.7em; /* Largura fixa */
    height: 1.7em; /* Altura fixa */
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .valor {
    position: relative; /* Para posicionar o sup corretamente */
    white-space: nowrap;
  }

  .titulo {
    color: #d3d3d3;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }
  .valor {
    color: #fff;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat, sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .custom-line {
    border-color: #969696;
    margin: inherit;
    margin: 8px;
  }

  .dark-layout .custom-line {
    border-color: #f5f5f5;
  }

  .textAlt {
    font-size: 0.9rem;
    color: #969696;
  }

  .dark-layout .textAlt {
    color: #f5f5f5;
  }

  .text-titulo {
    font-size: 1.3rem;
  }

  .text-valor {
    color: var(--primary);
    margin-bottom: unset;
    margin-top: 13px;
  }

  .container-valor {
    background-color: #f5f5f5;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 0;
    height: 210px;
    text-align: center; // Added to center the text
  }

  .dark-layout .container-valor {
    background-color: #273357;
  }

  .container-resultado {
    background-color: var(--primary);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    color: #ffffff;
    height: auto;
  }

  .container-valor,
  .container-resultado {
    max-width: 40vw; // Largura máxima aplicada a ambos
    margin-right: auto; // Margem direita automática para centralizar ambos
    margin-left: auto; // Margem esquerda automática para centralizar ambos
  }

  .container-valor {
    background-color: #f5f5f5; // Cor de fundo vermelho para a div principal
    border-radius: 10px 10px 0 0; // Bordas arredondadas em cima
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Sombra suave
    padding: 20px; // Espaçamento interno
    margin-top: 0px; // Margem superior
    margin-bottom: 0; // Margem inferior de 0 para reduzir em 30px em relação aos outros lados
    height: 300px; // Altura definida
  }

  .container-resultado {
    background-color: var(--primary); // Cor de fundo azul para a div de resultado
    border-radius: 0 0 10px 10px; // Bordas arredondadas embaixo
    padding: 15px; // Espaçamento interno
    color: #ffffff; // Texto branco
    height: auto;
    // A margem superior do .container-resultado não é definida aqui, pois ela dependerá
    // da posição do elemento no layout. Se precisar de espaço entre o .container-valor e
    // o .container-resultado, defina uma margem superior adequada aqui.
  }

  .textConsignado {
    color: var(--primary);
    font-weight: bolder;
    font-size: 1.5rem;
  }

  [dir='ltr'] .modal .modal-header {
    justify-content: center;
  }

  .corLinhaSlider {
    background-color: #016b3a;
  }
  .modalseguradoanalise > .modal-dialog > .modal-content > .modal-header {
    background-color: #f1c40f !important;
    justify-content: center;
  }

  .modalseguradoreprovado > .modal-dialog > .modal-content > .modal-header {
    background-color: #da2228 !important;
    justify-content: center;
  }

  .modalseguradotodoReprovado > .modal-dialog > .modal-content > .modal-header {
    background-color: #da2228 !important;
    justify-content: center;
  }

  #divCard {
    max-width: 700px;
  }

  #radiosTipoDeContrato > label {
    border: 1px solid var(--primary) !important;
  }

  #wrap {
    padding-top: 25px !important;
  }

  // Estilizar a modal de Swal
  // .swal2-popup .swal2-modal .swal2-icon-success .swal2-show {

  // }

  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #9b0808 0%, #9b0808 0%, #d3d3d3 0%, #d3d3d3 100%);
    border-radius: 5px;
    outline: none;
    top: 1px;
    margin-top: 30px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: url('./src/rectangle-slider.svg') no-repeat;
    background-size: contain;
    cursor: pointer;
    border: none;
    margin-bottom: 45px;
  }

  input[type='range']::-webkit-slider-thumb::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #ffffff transparent;

    left: 50%;
    transform: translateX(-50%);
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: url('./src/rectangle-slider.svg') no-repeat;
    background-size: contain;
    cursor: pointer;
    border: none;
    top: -5px;
  }

  input[type='range']::-webkit-slider-thumb::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 1px 5px;
    border-color: transparent transparent #ffffff transparent;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>
