<template>
  <div>
    <hr class="mb-50" />
    <b-row>
      <b-col cols="12" v-if="totalRows > 0 && isBusy">
        <b-skeleton-table :rows="1" :columns="7"> </b-skeleton-table>
      </b-col>
      <b-col cols="12" v-else-if="totalRows > 0 && !isBusy">
        <b-table
          ref="tabelaUser"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :busy="isBusy"
          :sort-by.sync="sortBy"
          :sort-direction="sortDirection"
          :items="itemsContratos"
          :fields="fieldsContratos"
          hover
          @row-clicked="visualizarContrato($event)"
          class="custom-hover-table"
        >
          <template #cell(statusFinanceiro)="row">
            <b-badge class="px-2 alert rounded-pill py-50 m-0 w-100" :class="`alert-${row.item.colorStatusFinanceiro}`">
              {{ row.item.statusFinanceiro ? row.item.statusFinanceiro : 'Não definido' }}
            </b-badge>
          </template>

          <template #cell(statusGenerico)="row">
            <b-badge class="px-2 alert rounded-pill py-50 m-0 w-100" :class="`alert-${row.item.color}`">
              {{ row.item.statusGenerico ? tiposStatusGenerico[row.item.statusGenerico] : 'Não definido' }}
            </b-badge>
          </template>

          <template #cell(acoes)="row">
            <div class="d-flex">
              <b-dropdown
                v-b-tooltip.hover
                title="Exportar"
                class="p-0"
                id="exportar"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                left
                bottom
              >
                <template #button-content>
                  <feather-icon icon="DownloadIcon" size="18" class="align-middle" />
                </template>
                <b-dropdown-item @click="exportarContrato(row.item, 'xlsx')">
                  <b-icon icon="file-earmark-spreadsheet-fill" size="16" />
                  <span class="align-middle ml-50">Excel</span>
                </b-dropdown-item>
                <b-dropdown-item @click="exportarContrato(row.item, 'pdf')">
                  <b-icon icon="file-earmark-text-fill" size="16" />
                  <span class="align-middle ml-50">PDF</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <b-card-body v-if="totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            style="align-items: center"
            label="Por Página"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>
          <!-- pagination -->
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-card-body>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValor, formatarDataCurto } from '@/utils/Functions/Formatacao/Formatacao'
  export default {
    components: {
      useJwt,
      formatarParaNumber,
      formatarValor,
      formatarDataCurto,
    },
    props: {
      isBusy: {
        type: Boolean,
      },
      userData: {
        type: Object,
      },
    },
    data() {
      return {
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'name',
        sortDirection: 'asc',
        fieldsContratos: [
          {
            key: 'codigo',
            label: 'Nº do Contrato',
            sortable: true,
          },
          {
            key: 'nomeCompleto',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'matricula',
            label: 'Matricula',
            sortable: true,
          },
          {
            key: 'valorContrato',
            label: 'Valor do Contrato',
            sortable: true,
          },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            sortable: true,
          },
          {
            key: 'statusFinanceiro',
            label: 'Status Financeiro',
          },
          {
            key: 'statusGenerico',
            label: 'Status Linha do Tempo',
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
        itemsContratos: [],
        tiposStatusGenerico: {
          criacao: 'Criação',
          contaBancariaSolicitada: 'Conta Bancária Solicitada',
          contaBancariaCriada: 'Conta Bancária Criada',
          contaBancariaNegada: 'Conta Bancária Negada',
          erroAberturaContaBancaria: 'Erro na Abertura da Conta Bancária',
          propostaEnviadaParaOBanco: 'Proposta Enviada para o Banco',
          erroAoEnviarPropostaParaOBanco: 'Erro ao Enviar Proposta para o Banco',
          contratoEmAndamento: 'Contrato em Andamento',
          gerandoCCB: 'Gerando CCB',
          ccbNegada: 'CCB Negada',
          ccbEnviadaAoTomador: 'CCB Enviada ao Tomador',
          ccbAguardandoAssinatura: 'CCB Aguardando Assinatura',
          ccbAssinada: 'CCB Assinada',
          ccbExpirada: 'CCB Expirada',
          aguardandoAverbacao: 'Aguardando Averbação',
          creditoDisponivel: 'Crédito Disponível',
          desembolsoEfetuado: 'Desembolso Efetuado',
          parcelaEmDia: 'Parcela em Dia',
          parcelaEmAtraso: 'Parcela em Atraso',
        },
        totalRows: 0,
      }
    },
    methods: {
      async carregarGrid(filtros) {
        this.isBusy = true
        const param = {
          ...filtros,
        }
        useJwt
          .post('financeiro/GetPesquisarContrato', param)
          .then(async (response) => {
            this.itemsContratos = response.data.contratos.map((item) => {
              return {
                ...item,
                valorParcela: formatarValor(item.valorParcela),
                valorContrato: formatarValor(item.valorContrato),
              }
            })
            this.totalRows = response.data.contratos.length
            await this.defineCorStatusLinhaDoTempo()
          })
          .catch((error) => {
            this.$message.error('Erro ao carregar Grid')
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },

      async exportarContrato(item, tipo) {},

      async visualizarContrato(contrato) {
        this.$router.push({
          name: 'Contrato',
          params: { id: contrato.id },
          query: { codigo: contrato.codigo },
        })
      },

      async defineCorStatusLinhaDoTempo() {
        this.itemsContratos.forEach((event) => {
          switch (event.tipoStatusLinhaTempo) {
            case 'Pendente':
              event.color = 'warning'
              break
            case 'Consolidado':
              event.color = 'success'
              break
            case 'Cancelado':
              event.color = 'danger'
              break
            default:
              event.color = 'dark'
              break
          }
        })

        this.itemsContratos.forEach((event) => {
          switch (event.statusFinanceiro) {
            case 'Pendente':
              event.colorStatusFinanceiro = 'warning'
              break
            case 'Consolidado':
              event.colorStatusFinanceiro = 'success'
              break
            case 'Cancelado':
              event.colorStatusFinanceiro = 'danger'
              break
            default:
              event.colorStatusFinanceiro = 'dark'
              break
          }
        })
      },
    },
  }
</script>
