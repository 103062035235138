<template>
  <div>
    <b-card>
      <!-- form -->
      <validation-observer ref="formularioCadastroTaxa">
        <b-form>
          <b-row>
            <b-col sm="2">
              <b-form-group label="Parcelas" label-for="parcelas">
                <validation-provider
                  #default="{ errors }"
                  name="Parcelas"
                  :rules="{
                    required: true,
                    parcelaMin: generalData.limiteParcelas,
                  }"
                >
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      type="text"
                      id="parcelas"
                      v-bloqueioCaractereMask="{ tamanho: 3, regex: /[^0-9]/ }"
                      v-model="dados.parcelas"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Parcelas"
                      placeholder="Parcelas"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Taxa Mensal" label-for="taxaMensal">
                <validation-provider #default="{ errors }" name="Taxa Mensal" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      type="text"
                      id="taxaMensal"
                      v-number="number"
                      v-model="dados.taxaMensal"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Taxa Mensal"
                      placeholder="Taxa Mensal"
                      @blur="convertToAnnual"
                    />
                    <b-input-group-append>
                      <span class="input-group-text">%</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Taxa Anual" label-for="taxaAnual">
                <validation-provider #default="{ errors }" name="Taxa Anual" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      type="text"
                      disabled
                      id="taxaAnual"
                      v-number="number"
                      v-model="dados.taxaAnual"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Taxa Anual"
                      placeholder="Taxa Anual"
                    />
                    <b-input-group-append>
                      <span class="input-group-text">%</span>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 22px; width: 160px"
                @click.prevent="createOrUpdate"
              >
                {{ !isBusy ? tituloBotao : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>

            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                style="margin-top: 22px; width: 160px"
                @click.prevent="eraseForm"
              >
                {{ !isBusy ? 'Limpar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- Senha -->
      <!-- <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal> -->
    </b-card>

    <b-card>
      <b-table
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="pesquisar.currentPage"
        :busy="isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong> Carregando...</strong>
          </div>
        </template>

        <!-- Campo Opções -->
        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="editar(row.item)">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteTax(row.item.id)">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Paginação -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelectInd" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPage"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, parcelaMin } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarParaNumber, formatarValorPorcentagem } from '@/libs/utils'
  import { bignumber, pow } from 'mathjs'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      parcelaMin,
      formatarParaNumber,
      formatarValorPorcentagem,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        dados: {
          nome: null,
          taxaMensal: null,
          taxaAnual: null,
        },
        acesso: {},
        number: 0,
        tituloBotao: 'Cadastrar',

        //Padrão Tabela
        items: [],
        fields: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'parcelas', label: 'Parcelas' },
          { key: 'taxaMensal', label: 'Taxa Mensal', formatter: (value) => formatarValorPorcentagem(value) },
          { key: 'taxaAnual', label: 'Taxa Anual', formatter: (value) => formatarValorPorcentagem(value) },
        ],
        pesquisar: { perPage: 25, currentPage: 1 },
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
      }
    },
    mounted() {
      this.getTaxas()
    },
    methods: {
      getTaxas() {
        this.isBusy = true
        useJwt
          .get(`produto/getTaxas/${this.generalData.id}`)
          .then((response) => {
            this.items = response.data
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      editar(item) {
        this.tituloBotao = 'Editar'
        this.dados = {
          ...item,
          taxaAnual: formatarParaNumber(item.taxaAnual) * 100,
          taxaMensal: formatarParaNumber(item.taxaMensal) * 100,
        }
      },
      async createOrUpdate() {
        this.isBusy = true

        const validacoes = await this.validarCampos()
        if (!validacoes) {
          this.isBusy = false
          return
        }

        const parametros = {
          id: this.dados.id,
          parcelas: this.dados.parcelas,
          taxaAnual: this.dados.taxaAnual,
          taxaMensal: this.dados.taxaMensal,
          produtoId: this.generalData.id,
        }

        useJwt
          .put(`produto/putTaxas`, parametros)
          .then(() => {
            if (this.dados.id) this.$message.success('Taxa cadastrada com sucesso!')
            else this.$message.success('Taxa atualizada com sucesso!')
          })
          .catch(() => {
            const tituloOperacao = this.dados.id ? 'atualizar' : 'cadastrar'
            console.error(`Erro ao ${tituloOperacao} taxa!`)
          })
          .finally(() => {
            this.eraseForm()
            this.getTaxas()
          })
      },

      async validarCampos() {
        const isFormValid = await this.$refs.formularioCadastroTaxa.validate()

        if (!isFormValid) {
          if (!this.dados.parcelas || !this.dados.taxaAnual || !this.dados.taxaMensal) {
            this.$message.error('Preencha os campos obrigatórios!')
            return
          } else {
            this.$message.error('Há campos com valores incorretos!')
            return
          }
        } else {
          return true
        }
      },
      deleteTax(id) {
        this.isBusy = true
        useJwt
          .delete(`produto/deleteTax`, id)
          .then(() => {
            this.$message.success('Taxa excluída com sucesso!')
            this.getTaxas()
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao excluir taxa!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      convertToAnnual() {
        try {
          if (!this.dados.taxaMensal) return (this.dados.taxaAnual = null)
          // Conversão da taxa mensal para BigNumber e para decimal
          const monthlyRateDecimal = bignumber(formatarParaNumber(this.dados.taxaMensal)).div(100)

          // Cálculo da taxa anual usando bignumber e pow
          const one = bignumber(1)
          const annualRateDecimal = pow(one.plus(monthlyRateDecimal), 12).minus(one)

          // Conversão da taxa anual para percentual
          this.dados.taxaAnual = annualRateDecimal.times(100).toFixed(2)
        } catch (error) {
          console.error('Erro no cálculo da taxa anual:', error)
        }
      },
      eraseForm() {
        this.$refs.formularioCadastroTaxa.reset()
        this.dados = {
          nome: null,
          taxaMensal: null,
          taxaAnual: null,
        }
        this.tituloBotao = 'Cadastrar'
      },
    },
  }
</script>
