import Swal from 'sweetalert2'

export function showLoading(title, text) {
  Swal.fire({
    title: title,
    text: text,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading()
    },
  })
}

export function closeLoading() {
  Swal.close()
}
