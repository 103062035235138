<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <span>Configurações de Integrações</span>
        </template>
        <b-card title="Integrações Sistema">
          <validation-observer ref="form">
            <b-form>
              <b-row md="12">
                <div></div>
                <b-col class="my-1" cols="12">
                  <h5>Integrações de Folha</h5>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="Desconto em Folha" label-for="descontoEmFolha">
                    <validation-provider #default="{ errors }" name="Desconto em Folha">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                        </b-input-group-prepend>
                        <b-form-input
                          id="descontoEmFolha"
                          type="text"
                          v-model="integracoes.descontoEmFolha"
                          placeholder="Link de integração"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row md="12">
                <div class="w-100 mx-1 my-1 linhaDivisoria"></div>
                <b-col class="my-1" cols="12">
                  <h5>Integrações de Boleto</h5>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="Emissão de boleto" label-for="emissaoBoleto">
                    <validation-provider #default="{ errors }" name="Emissão de boleto">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                        </b-input-group-prepend>
                        <b-form-input
                          id="emissaoBoleto"
                          type="text"
                          v-model="integracoes.emissaoBoleto"
                          placeholder="Link de integração"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row md="12">
                <div class="w-100 mx-1 my-1 linhaDivisoria"></div>
                <b-col class="my-1" cols="12">
                  <h5>Integrações de Dashboard</h5>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="Dashboard Cadastro" label-for="dashboardCadastro">
                    <validation-provider #default="{ errors }" name="Dashboard Cadastro">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                        </b-input-group-prepend>
                        <b-form-input
                          id="dashboardCadastro"
                          type="text"
                          v-model="integracoes.dashboardCadastro"
                          placeholder="Link de integração"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row md="12">
                <b-col cols="8">
                  <b-form-group label="Dashboard Proposta" label-for="dashboardProposta">
                    <validation-provider #default="{ errors }" name="Dashboard Proposta">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                        </b-input-group-prepend>
                        <b-form-input
                          id="dashboardProposta"
                          type="text"
                          v-model="integracoes.dashboardProposta"
                          placeholder="Link de integração"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row md="12">
                <b-col cols="8">
                  <b-form-group label="Dashboard Financeiro" label-for="dashboardFinanceiro">
                    <validation-provider #default="{ errors }" name="Dashboard Financeiro">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="LinkIcon" size="18" /> </span>
                        </b-input-group-prepend>
                        <b-form-input
                          id="dashboardFinanceiro"
                          type="text"
                          v-model="integracoes.dashboardFinanceiro"
                          placeholder="Link de integração"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row md="12">
                <div class="w-100 mx-1 my-1 linhaDivisoria"></div>
                <b-col class="my-1" cols="12">
                  <h5>Configuração de Processadora de Margem</h5>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="Valor por Parcela" label-for="valorPorParcela">
                    <validation-provider #default="{ errors }" name="Valor por Parcela">
                      
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text"> <feather-icon icon="DollarSignIcon" size="18" /> </span>
                        </b-input-group-prepend>

                        <b-form-input
                          id="valorPorParcela"
                          type="number"
                          v-model="integracoes.valorPorParcela"
                          placeholder="Valor por parcela"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="width: 160px"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="authESalve"
              >
                {{ !isBusy ? 'Salvar' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="width: 160px"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="limpar"
              >
                Limpar
              </b-button>
            </b-form>
          </validation-observer>
          <!-- INICIO DO VERIFICA SENHA -->
          <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
            <AutenticaSenha @autenticarEvent="autenticarMethod" />
          </b-modal>
        </b-card>
      </b-tab>
      <b-tab v-if="habilitaTac">
        <template #title>
          <span>Configurar TAC</span>
        </template>

        <b-card title="Configurações de Tac">
          <validation-observer ref="formTac" tag="form">
            <b-row>
              <b-col md="6">
                <b-form-group label="Configuração por Valor" label-for="configuracaoTacPorValor" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="configuracaoTacPorValor"
                    v-model="aux.configuracaoTacPorValor"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                    :disabled="aux.configuracaoTacPorParcela"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Configuração por Parcela"
                  label-for="configuracaoTacPorParcela"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="configuracaoTacPorParcela"
                    v-model="aux.configuracaoTacPorParcela"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                    :disabled="aux.configuracaoTacPorValor"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="aux.configuracaoTacPorValor ? 'Configuração por Valor' : 'Configuração por Parcela'"
                  label-for="configuracaoTacPorValor"
                  v-slot="{ ariaDescribedby }"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="aux.configuracaoTacPorValor ? 'Configuração por Valor' : 'Configuração por Parcela'"
                    rules="required"
                  >
                    <b-input-group v-if="aux.configuracaoTacPorValor" id="configuracaoTacPorValor" type="text">
                      <b-input-group-append>
                        <span class="input-group-text">R$</span>
                      </b-input-group-append>
                      <b-form-input v-model="tac.valorTac" v-number="number" placeholder=" 0,00" :raw="false" />
                    </b-input-group>

                    <b-form-input
                      v-else
                      id="configuracaoTacPorValor"
                      type="number"
                      placeholder="Digite a parcela..."
                      v-model="tac.parcelaTac"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Valor Cobrado" label-for="valorCobrado" v-slot="{ ariaDescribedby }">
                  <validation-provider #default="{ errors }" name="Valor cobrado" rules="required">
                    <b-input-group id="valorCobrado" type="text">
                      <b-input-group-append>
                        <span class="input-group-text">R$</span>
                      </b-input-group-append>
                      <b-form-input v-model="tac.valorCobrado" v-number="number" placeholder=" 0,00" :raw="false" />
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  style="width: 160px"
                  @click.prevent="salvarTac()"
                >
                  {{ isEditing ? 'Salvar' : 'Cadastrar' }}
                </b-button>
              </b-col>

              <b-col md="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  class="mt-2 mr-1"
                  style="width: 160px"
                  @click.prevent="limparTac()"
                >
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>

        <b-card class="tableOverflow">
          <b-table
            class="position-relative"
            :items="aux.configuracaoTacPorValor ? tacsValor : tacsParcela"
            :fields="aux.configuracaoTacPorValor ? fieldsValor : fieldsParcela"
          >
            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>
                <b-dropdown-item @click="editarTac(row.item)">
                  <feather-icon icon="EditIcon" size="16" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="excluirTac(row.item.id)">
                  <feather-icon icon="XIcon" size="16" />
                  <span class="align-middle ml-50">Excluir</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal'
  import { required, valor } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      NaoAutorizadoModal,
      required,
    },
    directives: {
      Ripple,
    },
    props: {
      instituto: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        mode: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        integracoes: {
          descontoEmFolha: null,
          emissaoBoleto: null,
          dashboardCadastro: null,
          dashboardProposta: null,
          dashboardFinanceiro: null,
          idTabela: null,
        },
        aux: {
          configuracaoTacPorValor: true,
          configuracaoTacPorParcela: false,
        },
        tac: {
          valorTac: null,
          parcelaTac: null,
          tipoTac: null,
          valorCobrado: null,
        },
        optionsSimNao: [
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ],
        number: 0,
        tacsValor: [],
        tacsParcela: [],
        fieldsValor: [
          {
            key: 'opcoes',
            label: 'Opções',
          },
          {
            key: 'valor',
            label: 'Valor Até',
          },
          {
            key: 'valorCobrado',
            label: 'Valor Cobrado',
          },
        ],
        fieldsParcela: [
          {
            key: 'opcoes',
            label: 'Opções',
          },
          {
            key: 'parcela',
            label: 'Parcela',
          },
          {
            key: 'valorCobrado',
            label: 'Valor Cobrado',
          },
        ],
        isEditing: false,
        tacEditId: null,
        habilitaTac: false,
      }
    },
    async mounted() {
      this.carregarGrid()
      await this.getTacs()
    },

    methods: {
      limpar() {
        this.integracoes.descontoEmFolha = null
        this.integracoes.emissaoBoleto = null
        this.integracoes.dashboardCadastro = null
        this.integracoes.dashboardProposta = null
        this.integracoes.dashboardFinanceiro = null
      },
      async carregarGrid() {
        const institutoSelecionado = this.instituto.id

        useJwt
          .get(`integracoes/${institutoSelecionado}`)
          .then((response) => {
            let itemsIntegracoes = response.data
            this.integracoes.descontoEmFolha = itemsIntegracoes.descontoEmFolha ?? null
            this.integracoes.emissaoBoleto = itemsIntegracoes.emissaoBoleto ?? null
            this.integracoes.dashboardCadastro = itemsIntegracoes.dashboardCadastro ?? null
            this.integracoes.dashboardProposta = itemsIntegracoes.dashboardProposta ?? null
            this.integracoes.dashboardFinanceiro = itemsIntegracoes.dashboardFinanceiro ?? null
            this.integracoes.idTabela = itemsIntegracoes.id
          })
          .catch((error) => {
            this.$message.error('Erro ao carregar os dados')
            console.error('error', error)
          })
      },
      salvar() {
        this.isBusy = true

        const id = this.instituto.id
        const dados = {
          descontoEmFolha: this.integracoes.descontoEmFolha || null,
          emissaoBoleto: this.integracoes.emissaoBoleto || null,
          dashboardCadastro: this.integracoes.dashboardCadastro || null,
          dashboardProposta: this.integracoes.dashboardProposta || null,
          dashboardFinanceiro: this.integracoes.dashboardFinanceiro || null,
          valorPorParcela: this.integracoes.valorPorParcela || null,
          id: this.integracoes.idTabela || null,
        }
        if (this.integracoes.idTabela) {
          useJwt
            .patch(`integracoes/${id}`, dados)
            .then((response) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso!',
                    text: 'Dados Atualizados com sucesso!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
            .finally(() => {
              this.isBusy = false
            })
        } else {
          useJwt
            .post(`integracoes/${id}`, dados)
            .then((response) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso!',
                    text: 'Dados Atualizados com sucesso!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
            .finally(() => {
              this.isBusy = false
            })
        }
      },
      authESalve() {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      async getTacs() {
        await useJwt.get(`cadastro/instituto/${this.instituto.id}`).then((response) => {
          this.habilitaTac = response.data.habilitaTac
          if (response.data.tipoTac == 'valor')
            (this.aux.configuracaoTacPorValor = true), (this.aux.configuracaoTacPorParcela = false)
          if (response.data.tipoTac == 'parcela')
            (this.aux.configuracaoTacPorParcela = true), (this.aux.configuracaoTacPorValor = false)
        })

        await useJwt.get(`tac/get-all/${this.instituto.id}`).then((response) => {
          this.tacsValor = response.data.filter((tac) => tac.valor != null)
          this.tacsParcela = response.data.filter((tac) => tac.parcela != null)
        })
      },

      async salvarTac() {
        if (!this.isEditing) {
          this.$refs.formTac.validate().then((success) => {
            if (success) {
              this.aux.configuracaoTacPorValor ? (this.tac.tipoTac = 'valor') : (this.tac.tipoTac = 'parcela')

              let data = {
                institutoId: this.instituto.id,
                tipoTac: this.tac.tipoTac,
                valorCobrado: this.tac.valorCobrado,
              }

              if (this.tac.tipoTac == 'valor') data = { ...data, valorTac: this.tac.valorTac }
              if (this.tac.tipoTac == 'parcela') data = { ...data, parcelaTac: this.tac.parcelaTac }

              useJwt
                .post('tac/post-tac', data)
                .then(() => {
                  this.$message.success('Tac criada com sucesso!')
                  this.getTacs()
                  this.limparTac()
                })
                .catch((error) => {
                  this.$message.error('Houve um erro ao cadastrar tac')
                })
            } else {
              this.$message.error('Digite o campos obrigatórios')
            }
          })
        } else {
          this.$refs.formTac.validate().then((success) => {
            if (success) {
              this.aux.configuracaoTacPorValor ? (this.tac.tipoTac = 'valor') : (this.tac.tipoTac = 'parcela')

              let data = {
                tacId: this.tacEditId,
                institutoId: this.instituto.id,
                tipoTac: this.tac.tipoTac,
                valorCobrado: this.tac.valorCobrado,
              }

              if (this.tac.tipoTac == 'valor') data = { ...data, valorTac: this.tac.valorTac }
              if (this.tac.tipoTac == 'parcela') data = { ...data, parcelaTac: this.tac.parcelaTac }
              useJwt
                .post(`tac/put-tac`, data)
                .then(() => {
                  this.$message.success('Tac editada com sucesso!')
                  this.getTacs()
                  this.limparTac()
                })
                .catch((error) => {
                  this.$message.error('Houve um erro ao cadastrar tac')
                })
            } else {
              this.$message.error('Digite o campos obrigatórios')
            }
          })
          this.isEditing = false
        }
      },
      limparTac() {
        this.tac = {
          valorTac: null,
          parcelaTac: null,
          parametroValorOuParcela: null,
          valorCobrado: null,
        }
      },

      editarTac(item) {
        this.isEditing = true
        this.tacEditId = item.id
        this.tac = {
          valorTac: item.valor,
          parcelaTac: item.parcela,
          valorCobrado: item.valorCobrado,
        }
      },

      excluirTac(id) {
        useJwt
          .delete(`tac/delete-tac`, id)
          .then(() => {
            this.$message.success('Tac excluída com sucesso!')
          })
          .catch((error) => {
            this.$message.error('Houve um erro ao excluir tac')
          })
        this.getTacs()
      },
    },
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }

  [dir] body #configuracaoTacPorValor > label.btn.btn-outline-primary.active > span {
    color: #ecf0f1;
  }
  [dir] body #configuracaoTacPorParcela > label.btn.btn-outline-primary.active > span {
    color: #ecf0f1;
  }

  #configuracaoTacPorValor label.btn.btn-outline-primary.disabled.active {
    background-color: #016b3a !important;
  }
  #configuracaoTacPorParcela label.btn.btn-outline-primary.disabled.active {
    background-color: #016b3a !important;
  }

  #configuracaoTacPorValor label.btn.btn-outline-primary.disabled.active {
    background-color: #016b3a !important;
  }
  #configuracaoTacPorParcela label.btn.btn-outline-primary.disabled.active {
    background-color: #016b3a !important;
  }
  .input-group-append .input-group-text {
    border-left: 1px solid #ced4da;
  }
  .tableOverflow {
    max-height: 50vh; /* Ajuste conforme necessário */
    overflow-y: auto;
  }
</style>
